import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { PlatformInvitationType } from '@medentee/enums'

import { queryClient } from 'queryClient'
import { LOGIN_VIA_CODE_URL, LOGIN_VIA_PASSWORD_URL, SIGNUP_USER_URL } from 'utils/urls'

import {
  Login,
  ForgotPassword,
  SignUpByLink,
  SignUpByEmail,
  Join,
  DeepLinkFallback
} from './screens'
import { useKeepRedirectUrl } from './screens/Login/hooks'
import { ToastContainer } from './components'
import { ModalRoot } from './components/ModalRoot'
import { ModalRootContextProvider } from './components/ModalRoot/ModalRootContext'
import { DownloadMobileAppInterrupt } from './components/DownloadMobileApp/DownloadMobileAppInterrupt'
import { useRedirectToDeepLinkFallback } from './screens/DeepLinkFallback/useRedirectToDeepLinkFallback'
import { LoginViaCode } from './screens/Login/LoginViaCode'

export const App = () => {
  useKeepRedirectUrl()
  useRedirectToDeepLinkFallback()

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ModalRootContextProvider>
          <ModalRoot />

          <Switch>
            <Route path={LOGIN_VIA_CODE_URL} exact component={LoginViaCode} />
            <Route path={LOGIN_VIA_PASSWORD_URL} exact component={Login} />
            <Route path="/open-app" component={DeepLinkFallback} />

            <Route path={SIGNUP_USER_URL}>
              <DownloadMobileAppInterrupt>
                <SignUpByEmail />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route path="/signup/invite/:token">
              <DownloadMobileAppInterrupt>
                <SignUpByLink />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route path="/signup/community/:token">
              <DownloadMobileAppInterrupt>
                <SignUpByLink platformInvitationType={PlatformInvitationType.COMMUNITY} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route path="/join/organization/:id/:type/:token">
              <DownloadMobileAppInterrupt>
                <Join platformInvitationType={PlatformInvitationType.STAFF} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route path="/join/community/:id/:type/:token">
              <DownloadMobileAppInterrupt>
                <Join platformInvitationType={PlatformInvitationType.COMMUNITY} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route path="/event/invite/:token">
              <DownloadMobileAppInterrupt>
                <SignUpByEmail platformInvitationType={PlatformInvitationType.EVENT} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route exact path="/join/event/:token">
              <DownloadMobileAppInterrupt>
                <SignUpByLink platformInvitationType={PlatformInvitationType.EVENT} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route exact path="/join/event/:id/:type/:token">
              <DownloadMobileAppInterrupt>
                <Join platformInvitationType={PlatformInvitationType.EVENT} />
              </DownloadMobileAppInterrupt>
            </Route>

            <Route
              exact={true}
              path={['/forgot-password', '/forgot-password/:token']}
              component={ForgotPassword}
            />
            <Route path="*" component={() => <Redirect to={LOGIN_VIA_CODE_URL} />} />
          </Switch>
        </ModalRootContextProvider>
      </QueryClientProvider>

      <ToastContainer autoClose={false} closeOnClick={false} draggable={false} />
    </>
  )
}
