import { Field } from 'react-final-form'
import React, { ChangeEvent } from 'react'

import { validation } from '../../../utils'
import { TextField } from '../../components'

export type TEmailFieldProps = {
  readOnly?: boolean
}

export const EmailField = ({ readOnly }: TEmailFieldProps) => (
  <Field
    name="email"
    validate={validation.composeValidators(validation.required(), validation.isEmail())}
  >
    {({ input, meta: { error, touched, invalid } }) => {
      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        input.onChange(event.target.value.trim() as any)
      }

      return (
        <TextField
          {...input}
          value={input.value}
          onChange={onChange}
          topLabel="Email*"
          autoFocus={true}
          invalid={touched && invalid}
          error={error}
          disabled={readOnly}
        />
      )
    }}
  </Field>
)
