import { messages } from './validationMessages'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from './constants'

type TValidatorResponse = string | null

export type TPersonNameMessageType = keyof Pick<typeof messages, 'name' | 'lastName'>

class Validation {
  public rules = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    personName: /^[\p{L}'\-\s]+$/u,
    digits: /^\d+$/,
    price: /^(?:[1-9][0-9]*|0)$/,
    password: new RegExp(
      // eslint-disable-next-line no-useless-escape
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\;\\(\\)\\"\\@\\#\\$\\%\\^\\&\\*\\-\\_\\!\\+\\=\\[\\]\\{\\}\\|\\:\\'\\,\\.\\?\\/\`\\~“”˝‘´’])[A-Za-z\\d\\;\\(\\)\\"\\@\\#\\$\\%\\^\\&\\*\\-\\_\\!\\+\\=\\[\\]\\{\\}\\|\\:\\'\\,\\.\\?\\/\`\\~“”˝‘´’]{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}$`
    ),
    onlySpaces: /^\s+$/,
    companyName: /^[0-9a-zA-Z'"-]+(\s[0-9a-zA-Z'"-]+)*$/
  }

  public required =
    (errMessage = messages.required) =>
    (value: any): TValidatorResponse => {
      if (Array.isArray(value)) {
        return value.length ? null : errMessage
      }

      let isOnlySpace = false
      if (typeof value === 'string') {
        isOnlySpace = this.rules.onlySpaces.test(value)
      }

      if (value === '' || isOnlySpace) {
        return errMessage
      }
      return value || value === 0 ? null : errMessage
    }

  public isNumber =
    (errMessage?: string) =>
    (value: number): TValidatorResponse => {
      if (value === undefined || !isNaN(value)) {
        return null
      }

      return errMessage ? errMessage : messages.isNumber
    }

  public isEmail =
    (errMessage = messages.email) =>
    (value: string): TValidatorResponse => {
      if (this.rules.email.test(value)) {
        return null
      }

      return errMessage
    }
  public isPassword =
    (errMessage = messages.password) =>
    (value: string): TValidatorResponse =>
      this.rules.password.test(value) ? null : errMessage

  public isPersonName =
    (type: TPersonNameMessageType, errMessage = messages[type]) =>
    (value: string): TValidatorResponse => {
      if (this.rules.personName.test(value)) {
        return null
      }

      return errMessage
    }

  public isPasswordConfirm =
    (errMessage?: string) =>
    (value: string, values: any): TValidatorResponse => {
      if (values.password !== value) {
        return errMessage ? errMessage : messages.confirmPassword
      }

      return null
    }

  public minLength =
    (min: number, message?: string) =>
    (value: string | any[]): TValidatorResponse =>
      value == null || value === '' || value.length >= min
        ? null
        : message || `${messages.minText} ${min}`

  public maxLength =
    (max: number, message?: string) =>
    (value: string | any[]): TValidatorResponse =>
      value == null || value === '' || value.length <= max
        ? null
        : message || `${messages.maxText} ${max}`

  public min =
    (min: number, message?: string) =>
    (value: number): TValidatorResponse =>
      value === undefined || value >= min ? null : message || `${messages.min} ${min}`

  public max =
    (max: number, message?: string) =>
    (value?: number): TValidatorResponse =>
      value === undefined || value <= max ? null : message || `${messages.max} ${max}`

  public digits = (errMessage?: string) => (value: string | number) =>
    value != null && value !== '' && !this.rules.digits.test(String(value))
      ? errMessage || messages.onlyDigits
      : null

  public composeValidators =
    (...validators: any[]) =>
    (value: any, values: any) =>
      validators.reduce((error, validator) => error || validator(value, values), null)

  public companyName =
    (errMessage = messages.companyName) =>
    (value: string) =>
      this.rules.companyName.test(value) ? null : errMessage

  public onlySpaces = (errMessage?: string) => (value: string) =>
    this.rules.onlySpaces.test(value) ? errMessage || 'Field shouldn’t contain spaces only' : null
}

const validation = new Validation()
export default validation
