import {
  FC,
  ReactNode,
  useCallback,
  SVGProps,
  ButtonHTMLAttributes,
  PropsWithChildren
} from 'react'
import cls from 'classnames'

import { ButtonSpinner, TButtonSpinnerColor } from 'App/components'
import { stopPropagation } from 'utils'

import { ButtonBaseContent } from './components/ButtonBaseContent/ButtonBaseContent'
import styles from './ButtonBase.module.scss'

export type TButtonSize = 'default' | 'md' | 'sm' | 'xs'
export type TButtonVariant = 'primary' | 'outlined' | 'text' | 'inline' | 'underlined'
export type TButtonType = 'button' | 'submit' | 'reset'
export type TButtonColor = 'primary' | 'secondary' | 'error'
export type TButtonFontWeight = 'normal' | 'bold' | 'semi-bold'

export type TButtonProps = PropsWithChildren<{
  loading?: boolean
  variant?: TButtonVariant
  size?: TButtonSize
  color?: TButtonColor
  iconPosition?: 'left' | 'right'
  hideIcon?: boolean
  icon?: ReactNode | SVGProps<SVGSVGElement>
  adaptive?: boolean
  fontWeight?: TButtonFontWeight
}> &
  ButtonHTMLAttributes<HTMLButtonElement>

const ButtonBase: FC<TButtonProps> = ({
  children,
  disabled,
  loading,
  hideIcon,
  icon,
  onClick,
  className = '',
  type = 'button',
  variant = 'primary',
  size = 'default',
  iconPosition = 'left',
  color = 'primary',
  adaptive = true,
  fontWeight = '',
  onMouseDown = (e) => e.stopPropagation(),
  ...rest
}) => {
  const spinnerColor: TButtonSpinnerColor =
    variant !== 'outlined' &&
    variant !== 'text' &&
    variant !== 'inline' &&
    variant !== 'underlined' &&
    !disabled
      ? 'default'
      : 'secondary'

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (loading) {
        stopPropagation(event)
        event.preventDefault()
        return
      }

      if (onClick) {
        onClick(event)
      }
    },
    [loading, onClick]
  )

  return (
    <button
      type={type}
      disabled={disabled}
      className={cls(
        { [styles.btnWithIcon]: !!icon, [styles.adaptive]: adaptive, [styles.loading]: loading },
        styles.btn,
        styles[variant],
        styles[`font-weight-${fontWeight}`],
        styles[`btn-${size}`],
        styles[`color-${color}`],
        className
      )}
      onClick={handleClick}
      // prevent Select opening when button is passed to endAdornment slot
      onMouseDown={onMouseDown}
      {...rest}
    >
      {loading ? (
        <ButtonSpinner color={spinnerColor} className={styles.spinner} />
      ) : (
        <ButtonBaseContent icon={icon} iconPosition={iconPosition} hideIcon={hideIcon}>
          {children}
        </ButtonBaseContent>
      )}
    </button>
  )
}

export default ButtonBase
