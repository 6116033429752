import cls from 'classnames'

import styles from './ButtonSpinner.module.scss'

export type TButtonSpinnerColor = 'default' | 'secondary'

export type TButtonSpinnerProps = PropsWithClassName<{
  width?: number | string
  height?: number | string
  color?: TButtonSpinnerColor
}>

export const ButtonSpinner = ({
  className,
  width = 20,
  height = 20,
  color = 'default'
}: TButtonSpinnerProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: 'auto', background: 'none' }}
    width={width}
    height={height}
    display="block"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
  >
    <circle
      cx="50"
      cy="50"
      r="30"
      fill="none"
      strokeDasharray="141.37166941154067 49.12388980384689"
      strokeWidth="10"
      transform="rotate(344.693 50 50)"
      className={cls({
        [styles.circle]: true,
        [color]: true
      })}
    >
      <animateTransform
        attributeName="transform"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
    </circle>
  </svg>
)
