import React, { FC } from 'react'
import cls from 'classnames'

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'utils/validation/constants'
import { ReactComponent as CheckMarkIcon } from 'assets/icons/CheckMark.svg'

import styles from './PasswordStrengthChecker.module.scss'

export type TPasswordStrengthCheckerProps = {
  password: string
  repeatPassword: string
}

type TPasswordStrengthType = 'min' | 'max' | 'lower' | 'upper' | 'num' | 'symbol' | 'match'
type TPasswordStrengthConfig = {
  key: number
  text: string
  type: TPasswordStrengthType
}

const passwordStrengthConfig: TPasswordStrengthConfig[] = [
  {
    text: `A minimum of ${MIN_PASSWORD_LENGTH} characters`,
    key: 1,
    type: 'min'
  },
  {
    text: `A maximum of ${MAX_PASSWORD_LENGTH} characters`,
    key: 2,
    type: 'max'
  },
  {
    text: 'Latin alphabet lowercase characters',
    key: 3,
    type: 'lower'
  },
  {
    text: 'Latin alphabet uppercase characters',
    key: 4,
    type: 'upper'
  },
  {
    text: 'Numbers (0-9)',
    key: 5,
    type: 'num'
  },
  {
    text: 'Symbols',
    key: 6,
    type: 'symbol'
  },
  {
    text: 'Password matching',
    key: 7,
    type: 'match'
  }
]

const passwordValidationMap = {
  min: MIN_PASSWORD_LENGTH,
  max: MAX_PASSWORD_LENGTH,
  lower: /[a-z]/,
  upper: /[A-Z]/,
  num: /[0-9]/,
  symbol:
    // eslint-disable-next-line no-useless-escape
    /[\<\>\;\(\)\"\@\#\$\%\^\&\*\-\_\!\+\=\[\]\{\}\|\\\:\'\,\.\?\/\`\~“”˝‘´’]/
}

const passwordValidationHelperMap = {
  lower: /[^a-z]/,
  upper: /[^A-Z]/,
  symbol:
    // eslint-disable-next-line no-useless-escape
    /[\<\>\;\(\)\"\@\#\$\%\^\&\*\-\_\!\+\=\[\]\{\}\|\\\:\'\,\.\?\/\`\~“”˝‘´’]/,
  // eslint-disable-next-line no-useless-escape
  full: /^[a-zA-Z0-9\<\>\;\(\)\"\@\#\$\%\^\&\*\-\_\!\+\=\[\]\{\}\|\\\:\'\,\.\?\/\`\~“”˝‘´’]{0,}$/
}

const isOtherCharsValidated = (
  password: string,
  type: Extract<TPasswordStrengthType, 'lower' | 'upper' | 'symbol'>
) =>
  passwordValidationHelperMap.full.test(
    password.match(new RegExp(passwordValidationHelperMap[type], 'g'))?.join('') ?? ''
  )

const isCompleted = (type: TPasswordStrengthType, password: string, repeatPassword: string) => {
  switch (type) {
    case 'min':
      return password.length >= passwordValidationMap.min
    case 'max':
      return (
        password.length >= passwordValidationMap.min && password.length <= passwordValidationMap.max
      )
    case 'lower':
      return passwordValidationMap.lower.test(password) && isOtherCharsValidated(password, type)
    case 'upper':
      return passwordValidationMap.upper.test(password) && isOtherCharsValidated(password, type)
    case 'num':
      return passwordValidationMap.num.test(password)
    case 'symbol':
      return passwordValidationMap.symbol.test(password) && isOtherCharsValidated(password, type)
    case 'match':
      return !!password && !!repeatPassword && password === repeatPassword

    default:
      return false
  }
}

export const PasswordStrengthChecker: FC<TPasswordStrengthCheckerProps> = ({
  password,
  repeatPassword
}) => (
  <ul className={styles.list}>
    {passwordStrengthConfig.map(({ text, key, type }) => {
      const itemCompleted = isCompleted(type, password, repeatPassword)

      return (
        <li
          key={key}
          className={cls({
            [styles.item]: true,
            [styles.itemCompleted]: itemCompleted
          })}
        >
          {itemCompleted && <CheckMarkIcon className={styles.icon} />} {text}
        </li>
      )
    })}
  </ul>
)
