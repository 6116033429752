import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import isArray from 'lodash/isArray'

import { redirectKey, toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

type TToastType = 'info' | 'warn' | 'error' | 'success'

const isToastType = (type: string): boolean => ['info', 'warn', 'error', 'success'].includes(type)

export const useKeepRedirectUrl = () => {
  const { location, replace } = useHistory()

  useEffect(() => {
    const redirectUrl = parse(location.search).r
    const queryType = parse(location.search).type ?? ''
    const queryMessage = parse(location.search).message ?? ''
    const type = isArray(queryType) ? queryType[0] : queryType
    const message = isArray(queryMessage) ? queryMessage[0] : queryMessage

    if (location.search && isToastType(type) && message) {
      toast[type as TToastType](message, toastDefaultOptions)
    }

    if (location.search && redirectUrl) {
      window.sessionStorage.setItem(
        redirectKey,
        isArray(redirectUrl) ? redirectUrl[0] : redirectUrl
      )
      replace(LOGIN_VIA_CODE_URL)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
