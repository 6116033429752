import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { SendEmailForm, SendNewPasswordForm } from 'App/screens'

export const ForgotPassword = () => (
  <Switch>
    <Route exact={true} path="/forgot-password" component={SendEmailForm} />
    <Route exact={true} path="/forgot-password/:token" component={SendNewPasswordForm} />
  </Switch>
)
