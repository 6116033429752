import { getAllTimezones } from 'countries-and-timezones'

import { TCity } from '../api/signup'

export const allTimezones = getAllTimezones()

export const getCityNameWithTimezone = (cityName: string, timezone: string) => {
  if (allTimezones[timezone]) {
    const utc = allTimezones[timezone].dstOffsetStr

    return `${cityName} (UTC ${utc[0]} ${utc[1] !== '0' ? utc[1] : ''}${utc.substring(2)})`
  }

  return cityName
}

export const getCityWithTimezone = (value: TCity) => ({
  ...value,
  cityName: getCityNameWithTimezone(value.cityName, value.timezone)
})
