import isArray from 'lodash/isArray'

import { TError } from 'types'
import { ECodeErrors } from 'enums'
import { toast } from 'App/components'
import { toastDefaultOptions } from 'globalConstants'

export const errorParsing = (error: TError) => {
  if (error) {
    if (error.response && error.response.data) {
      if (error.response.data.payload && error.response.data.payload.message) {
        return error.response.data.payload.message
      }

      if (error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          return error.response.data.message
        }

        if (isArray(error.response.data.message)) {
          return error.response.data.message.join('; ')
        }
      }
    }

    if (error.message) {
      return error.message
    }

    return 'Default error message'
  }
  return null
}

export const isMatchErrorCode = (e: TError, code: ECodeErrors) =>
  e?.response?.data?.code === ECodeErrors[code] ||
  e?.response?.data?.response?.code === ECodeErrors[code]

export const handleError = (e: TError) => {
  toast.error(errorParsing(e), toastDefaultOptions)
}
