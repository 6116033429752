import React, { ChangeEvent, ClipboardEvent, useCallback, useRef, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { FormApi } from 'final-form'

import config from 'config'
import { LOGIN_VIA_PASSWORD_URL } from 'utils/urls'
import { redirectKey, toastDefaultOptions } from 'globalConstants'
import { errorParsing, validation } from 'utils'
import { BrandCard, TextField, toast, Button } from 'App/components'
import { useGoogleReCaptcha } from 'App/components/hooks'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { authorizeViaCode, TAuthorizationOptions, TLoginValues } from 'api/auth'

import { EmailField } from './EmailField'
import styles from './Login.module.scss'

type TLocation = {
  goBack?: boolean
}

const CONFIRMATION_CODE_LENGTH = 6
const NUMBERS_ONLY_REGEXP = /\D/g

export const LoginViaCode = () => {
  const { push, goBack } = useHistory()
  const location = useLocation<TLocation>()

  const newUserEmail = queryString.parse(location.search)?.email?.toString()
  const shouldUseUrlEmail = Boolean(newUserEmail && !validation.isEmail()(newUserEmail))
  const canGoBack = location.state?.goBack

  const [loading, setLoading] = useState(false)
  const [resending, setResending] = useState(false)
  const [disabledLoginButton, setDisabledLoginButton] = useState(false)
  const [body, setBody] = useState<TLoginValues>({})
  const { isProcessing, resetCaptcha, executeCaptcha, renderCaptcha } = useGoogleReCaptcha()
  const [isCodeSend, setIsCodeSend] = useState(false)

  const formApi = useRef<FormApi>()

  const authorization = useCallback(
    async ({ values, recaptchaResponse }: TAuthorizationOptions) => {
      setLoading(true)

      try {
        await authorizeViaCode({
          values,
          recaptchaResponse
        })
        setLoading(false)

        if (!values.token) {
          toast.success('Email with code is sent!', toastDefaultOptions)
          setIsCodeSend(true)
        } else {
          setDisabledLoginButton(true)
          const redirectUrl =
            window.sessionStorage.getItem(redirectKey) || config.defaultRedirectUrl

          window.sessionStorage.removeItem(redirectKey)
          window.location.href = redirectUrl
        }
      } catch (error) {
        setLoading(false)

        let errorMessage = errorParsing(error)

        if (errorMessage === 'Account deleted.') {
          errorMessage = 'Account email or code is incorrect.'
        }

        toast.error(errorMessage, toastDefaultOptions)
      }
    },
    []
  )

  const handleOnSubmit = useCallback(
    (values: TLoginValues) => {
      setBody(values)

      if (!config.recaptchaDisabled) {
        resetCaptcha()
        executeCaptcha()
      }

      if (config.recaptchaDisabled) {
        authorization({ values })
      }
    },
    [authorization, executeCaptcha, resetCaptcha]
  )
  const handleUsePasswordClick = () => push(LOGIN_VIA_PASSWORD_URL)
  const handleBackClick = () => {
    if (canGoBack) {
      goBack()
    } else {
      setIsCodeSend(false)
      formApi.current?.change('token', '')
    }
  }

  return (
    <BrandCard
      title="Log In"
      onBack={(isCodeSend && !shouldUseUrlEmail) || canGoBack ? handleBackClick : undefined}
    >
      {renderCaptcha((token) => authorization({ values: body, recaptchaResponse: token }))}
      <Form onSubmit={handleOnSubmit} initialValues={{ email: newUserEmail }}>
        {({ values, handleSubmit, form }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {(isCodeSend || shouldUseUrlEmail) && (
              <p className={styles.description}>
                We've sent a code to your email address. Please enter the code on this screen.
              </p>
            )}
            <div className={styles.formFieldset}>
              <EmailField readOnly={isCodeSend || shouldUseUrlEmail} />

              {(isCodeSend || shouldUseUrlEmail) && (
                <Field
                  validate={validation.composeValidators(
                    validation.required(),
                    validation.digits(),
                    validation.maxLength(CONFIRMATION_CODE_LENGTH),
                    validation.minLength(CONFIRMATION_CODE_LENGTH)
                  )}
                  name="token"
                >
                  {({ input, meta: { error, touched, invalid } }) => {
                    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                      const text = event.target.value.replace(NUMBERS_ONLY_REGEXP, '')

                      input.onChange(text)
                    }

                    formApi.current = form
                    const onPaste = (event: ClipboardEvent<HTMLDivElement>) => {
                      event.preventDefault()

                      const { clipboardData } = event
                      const value = clipboardData
                        .getData('text/plain')
                        .replace(NUMBERS_ONLY_REGEXP, '')
                        .slice(0, 6)

                      onChange({
                        target: {
                          value
                        }
                      } as ChangeEvent<HTMLInputElement>)
                    }

                    return (
                      <TextField
                        {...input}
                        topLabel="Confirmation code*"
                        autoFocus={true}
                        inputProps={{ maxLength: CONFIRMATION_CODE_LENGTH }}
                        invalid={touched && invalid}
                        error={error}
                        onChange={onChange}
                        onPaste={onPaste}
                      />
                    )
                  }}
                </Field>
              )}
            </div>
            <div className={styles.buttons}>
              <Button
                type="submit"
                loading={loading || isProcessing}
                disabled={disabledLoginButton}
                className={styles.button}
              >
                {isCodeSend || shouldUseUrlEmail ? 'Log In' : 'Send Email Code'}
              </Button>
              {isCodeSend || shouldUseUrlEmail ? (
                <Button
                  variant="underlined"
                  loading={resending}
                  onClick={() => {
                    setResending(true)
                    authorizeViaCode({ values: { email: values.email } })
                      .then(() => toast.success('Code is sent successfully!', toastDefaultOptions))
                      .finally(() => setResending(false))
                  }}
                >
                  I did not receive an email
                </Button>
              ) : (
                <Button variant="text" icon={<LockIcon />} onClick={handleUsePasswordClick}>
                  Use Password
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    </BrandCard>
  )
}
