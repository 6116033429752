import React, { FC, ReactNode, useEffect, useState } from 'react'
import cls from 'classnames'
// FIXME: Move from the main application
import OriginalTextField, { TextFieldProps } from '@material-ui/core/TextField'

import { messages } from 'utils/validation/validationMessages'

import styles from './TextField.module.scss'

export enum ETextFieldSize {
  SM = 'sm',
  MD = 'md'
}

export type TErrorProps = {
  invalid?: boolean
  error?: string | null
}

export type TTextFieldProps = {
  errorLabel?: string | null
  topLabel?: ReactNode | string | null
  valueLengthMax?: number
  size?: ETextFieldSize
  classes?: Partial<Record<TTextFieldClasses, string>>
} & Omit<TextFieldProps, 'variant' | 'helperText' | 'label' | 'size' | 'className'> &
  TErrorProps

type TTextFieldClasses = 'wrapper' | 'root'

export const TextField: FC<TTextFieldProps> = ({
  size = ETextFieldSize.MD,
  error,
  invalid,
  valueLengthMax,
  value,
  defaultValue,
  onChange,
  topLabel,
  errorLabel,
  classes,
  ...rest
}) => {
  const [valueLength, setValueLength] = useState<number>(0)

  useEffect(() => {
    if (defaultValue && typeof defaultValue === 'string') {
      setValueLength(defaultValue.length)
    } else if (value && typeof value === 'string') {
      setValueLength(value.length)
    }
  }, [defaultValue, value])

  useEffect(() => {
    const newValueLength = (value as any).length

    if (newValueLength !== valueLength) {
      setValueLength(newValueLength)
    }
  }, [value, valueLength])

  return (
    <div className={cls(styles.wrapper, classes?.wrapper)}>
      {topLabel && <div className={styles.label}>{topLabel}</div>}
      <OriginalTextField
        className={cls(
          styles.rootField,
          size === ETextFieldSize.SM && styles.rootFieldSmall,
          classes?.root
        )}
        error={invalid}
        onChange={(event) => {
          if (onChange) {
            onChange(event)
          }
        }}
        value={value}
        defaultValue={defaultValue}
        variant={'outlined'}
        {...rest}
      />
      {error !== messages.maxText && invalid && <span className={styles.errorLabel}>{error}</span>}
      {!error && valueLengthMax && (
        <div
          className={cls({
            [styles.valueLength]: true,
            [styles.valueLengthSuccess]: valueLength > 0,
            [styles.valueLengthFail]: valueLength > valueLengthMax
          })}
        >
          {valueLength}/{valueLengthMax}
        </div>
      )}
    </div>
  )
}
