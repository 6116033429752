// TODO --- ADD i18 lib
export const messages = {
  required: 'Field is required',
  email: 'Email is incorrect',
  password: 'Password is incorrect',
  confirmPassword: 'Passwords mismatch',
  name: 'First name is incorrect ',
  lastName: 'Last name is incorrect',
  minText: 'Should be greater or equal to',
  maxText: 'Should be less or equal to',
  min: 'Should be greater or equal to',
  max: 'Should be less or equal to',
  minDate: 'Should be later than',
  maxDate: 'Should be earlier than',
  twoFACode: 'Code only 6 digits',
  isNumber: 'Must be a number',
  link: 'Invalid link',
  onlyDigits: 'Only digits',
  price: 'Invalid price',
  zip: 'Invalid Zip',
  companyName: 'Company name is incorrect'
}
