import { ReactNode, createContext, useContext, useReducer, useCallback } from 'react'

import { modalReducer, initialModalState } from './ModalRootContext.reducer'
import {
  TModalState,
  TSetModalTitleActionPayload,
  TShowModalActionPayload
} from './ModalRootContext.types'
import * as actionTypes from './ModalRootContext.actionTypes'
import { EModalComponents } from './ModalRoot.enums'

type TContextProps = TModalState & {
  hideModal: () => void
  showModal: <T extends EModalComponents>(payload: TShowModalActionPayload<T>) => void
  setModalTitle: (payload: TSetModalTitleActionPayload) => void
}

export type TChatCreateGroupContextProviderProps = {
  children: ReactNode
}

const ModalRootContext = createContext<TContextProps>({} as TContextProps)

export const useModalRootContext = () => {
  const context = useContext(ModalRootContext)

  if (context === undefined) {
    throw new Error('useModalRootContext must be used within a ModalRootContextProvider')
  }

  return context
}

export const ModalRootContextProvider = ({ children }: TChatCreateGroupContextProviderProps) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState)

  const showModal = useCallback<TContextProps['showModal']>((payload) => {
    dispatch({ type: actionTypes.SHOW_MODAL, payload })
  }, [])

  const hideModal = useCallback(() => {
    dispatch({ type: actionTypes.HIDE_MODAL })
  }, [])

  const setModalTitle = useCallback<TContextProps['setModalTitle']>((payload) => {
    dispatch({ type: actionTypes.SET_MODAL_TITLE, payload })
  }, [])

  return (
    <ModalRootContext.Provider
      value={{
        ...state,
        hideModal,
        showModal,
        setModalTitle
      }}
    >
      {children}
    </ModalRootContext.Provider>
  )
}
