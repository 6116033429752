import React, { FC } from 'react'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import OriginalCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import cls from 'classnames'

import styles from './Checkbox.module.scss'

export type TCheckboxProps = {
  label?: string
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>
} & CheckboxProps

export const Checkbox: FC<TCheckboxProps> = ({
  label,
  formControlLabelProps,
  className = '',
  ...rest
}) => {
  if (label !== undefined) {
    return (
      <FormControlLabel
        {...formControlLabelProps}
        control={
          <OriginalCheckbox
            {...rest}
            className={cls({
              [styles.root]: true,
              [className]: !!className
            })}
          />
        }
        label={label}
      />
    )
  }
  return (
    <OriginalCheckbox
      {...rest}
      className={cls({
        [styles.root]: true,
        [className]: !!className
      })}
    />
  )
}
