import { useLayoutEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { stringify } from 'query-string'

import { getDeviceInfo } from 'utils/detectDevice'
import { parseJwt } from 'utils'
import { SIGNUP_USER_URL } from 'utils/urls'

import { TDeepLinkFallbackContentVariant } from './DeepLinkFallback'

const INVITE_ROUTES = [
  SIGNUP_USER_URL,
  '/signup/invite/:token',
  '/signup/community/:token',
  '/join/event/:token',
  '/event/invite/:token'
]
const JOIN_ROUTES = [
  '/join/organization/:id/:type/:token',
  '/join/community/:id/:type/:token',
  '/join/event/:id/:type/:token'
]

export const useRedirectToDeepLinkFallback = () => {
  const { replace } = useHistory()
  const inviteMatch = useRouteMatch<Record<'token', string>>(INVITE_ROUTES)
  const joinRoutesMatch = useRouteMatch(JOIN_ROUTES)

  const { isAndroid, isIOS, isMobile } = getDeviceInfo()
  const showForMobile = isMobile && (isAndroid || isIOS)

  useLayoutEffect(() => {
    if (
      ((inviteMatch?.isExact && inviteMatch.url) ||
        (joinRoutesMatch?.isExact && joinRoutesMatch.url)) &&
      showForMobile
    ) {
      const token = inviteMatch?.params?.token ?? ''
      const contentType: TDeepLinkFallbackContentVariant =
        token && parseJwt(token)?.confirm ? 'confirm_email' : 'default'

      let pathname = ''

      if (inviteMatch?.isExact && inviteMatch.url) {
        pathname = inviteMatch.url
      }

      if (joinRoutesMatch?.isExact && joinRoutesMatch.url) {
        pathname = joinRoutesMatch.url
      }

      replace({
        pathname: '/open-app',
        search: stringify({ contentType, pathname })
      })
    }
  }, [joinRoutesMatch?.isExact, joinRoutesMatch?.url, inviteMatch, replace, showForMobile])
}
