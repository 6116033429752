import { AxiosError } from 'axios'
import { QueryClient } from '@tanstack/react-query'

import { handleError } from 'utils'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e) => handleError(e as AxiosError)
    },
    mutations: {
      retry: false,
      onError: (e) => handleError(e as AxiosError)
    }
  }
})
