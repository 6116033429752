import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'

import { history } from 'utils/history'

import { App } from './App'
import './assets/style/index.scss'
import 'antd/dist/antd.css'

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
)
