import React, { useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import {
  AccountTypeNames,
  BusinessAccountRoleIdEnum,
  BusinessAccountRoleNameEnum
} from '@medentee/enums'

import config from 'config'
import { redirectKey, toastDefaultOptions } from 'globalConstants'
import { api, API } from 'services/api'
import { errorParsing, isMatchErrorCode, validation } from 'utils'
import { ECodeErrors } from 'enums'
import {
  BrandCard,
  BrandCardHeader,
  PasswordField,
  TwoFactorChecker,
  toast,
  Button
} from 'App/components'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'
import { useGoogleReCaptcha } from 'App/components/hooks'
import { TCaptchaToken } from 'App/components/GoogleReCaptcha'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'

import styles from './Login.module.scss'
import { EmailField } from './EmailField'

type TLoginValues = {
  email?: string
  password?: string
}

type TAccountRole = {
  id: BusinessAccountRoleIdEnum
  name: BusinessAccountRoleNameEnum
  createdAt?: string | Date
  updatedAt?: string | Date
}

type TSpecialization = {
  id: string
  name: string
}

type TAssociatesProfessions = {
  id: string
  name: string
  specializations: TSpecialization[]
}

type TAccountCity = {
  id: string
  cityName: string
  countryCode: string
  timezone: string
}

type TAccountCountry = {
  code: string
  countryName: string
}

type TAccount = {
  lastSeen: string
  id: string
  email: string
  suspended: boolean
  firstName: string
  lastName: string
  type: {
    id: string
    name: AccountTypeNames
  }
  companyName: string | null
  displayUserName: string
}

type TAccountData = {
  id: string
  email: string
  suspended: boolean
  firstName: string
  lastName: string
  professions: TAssociatesProfessions[]
  type: {
    id: string
    name: AccountTypeNames
  }
  city: TAccountCity | null
  country: TAccountCountry
  lastSeen: string
  companyName: string
  hasBusinessAccount: boolean
  displayUserName: string
  professionalAccount?: TAccount
  unreadNotificationCount?: number
  role?: TAccountRole
  hasConnectedAccount?: boolean
}

type TAuthorizationResponse =
  | {
      result: {
        twoFAEnabled: boolean
        tokenExpirationDate?: string
      }
      error?: any
    }
  | TAccountData

type TAuthorizationOptions = {
  values: TLoginValues

  recaptchaResponse?: TCaptchaToken
  twoFACode?: string
  twoFAResend?: boolean
}

export const Login = () => {
  const { push } = useHistory()
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [disabledLoginButton, setDisabledLoginButton] = useState(false)
  const [body, setBody] = useState<TLoginValues>({})
  const { isProcessing, resetCaptcha, executeCaptcha, renderCaptcha } = useGoogleReCaptcha()

  const [expired2FATokenDate, setExpired2FATokenDate] = useState<Date | null>(null)
  const [codeInvalid, setCodeInvalid] = useState<boolean>(false)

  const authorization = useCallback(
    async ({ values, recaptchaResponse, twoFACode, twoFAResend }: TAuthorizationOptions) => {
      twoFAResend ? setProcessing(true) : setLoading(true)

      try {
        const { data } = await api.post<TAuthorizationResponse>(`${API.API_AUTH_URL}${API.LOGIN}`, {
          ...values,
          token: twoFACode,
          isResend: twoFAResend,
          recaptchaResponse
        })
        setLoading(false)
        setProcessing(false)

        const redirect = () => {
          const redirectUrl =
            window.sessionStorage.getItem(redirectKey) || config.defaultRedirectUrl

          window.sessionStorage.removeItem(redirectKey)
          window.location.href = redirectUrl
        }

        if (
          data.hasOwnProperty('result') &&
          data.result.twoFAEnabled &&
          data.result.tokenExpirationDate
        ) {
          setExpired2FATokenDate(new Date(data.result.tokenExpirationDate))
        } else {
          setDisabledLoginButton(true)
          redirect()
        }
      } catch (error) {
        setLoading(false)
        setProcessing(false)

        if (isMatchErrorCode(error, ECodeErrors.TOKEN_INVALID)) {
          setCodeInvalid(true)
        } else {
          let errorMessage = errorParsing(error)

          if (errorMessage === 'Account deleted.') {
            errorMessage = 'Account name or password is incorrect.'
          }

          toast.error(errorMessage, toastDefaultOptions)
        }
      }
    },
    []
  )

  const handleClearError = useCallback(() => setCodeInvalid(false), [])

  const handle2FAConfirm = useCallback(
    (code: string) => {
      authorization({
        values: body,
        twoFACode: code
      })
    },
    [authorization, body]
  )

  const handleResendCode = useCallback(() => {
    authorization({
      values: body,
      twoFAResend: true
    })
  }, [authorization, body])

  const handleOnSubmit = useCallback(
    (values: TLoginValues) => {
      setBody(values)

      if (!config.recaptchaDisabled) {
        resetCaptcha()
        executeCaptcha()
      }

      if (config.recaptchaDisabled) {
        authorization({ values })
      }
    },
    [authorization, executeCaptcha, resetCaptcha]
  )
  const handleLoginViaCodeClick = () => push(LOGIN_VIA_CODE_URL)

  const displayLoginForm = useCallback(
    () => (
      <Form onSubmit={handleOnSubmit}>
        {({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formFieldset}>
              <EmailField />
              <Field
                name="password"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.isPassword()
                )}
              >
                {({ input, meta: { error, touched, invalid } }) => (
                  <PasswordField
                    {...input}
                    topLabel="Password*"
                    invalid={touched && invalid}
                    error={error}
                  />
                )}
              </Field>
            </div>

            <Button
              type="submit"
              loading={loading || isProcessing}
              disabled={disabledLoginButton}
              className={styles.button}
            >
              Log In
            </Button>
          </form>
        )}
      </Form>
    ),
    [handleOnSubmit, loading, isProcessing, disabledLoginButton]
  )

  return (
    <BrandCard>
      <BrandCardHeader title="Log In" />
      {renderCaptcha((token) => authorization({ values: body, recaptchaResponse: token }))}

      {expired2FATokenDate ? (
        <TwoFactorChecker
          processing={processing}
          onConfirm={handle2FAConfirm}
          onResendCode={handleResendCode}
          expiredTokenDate={expired2FATokenDate}
          invalid={codeInvalid}
          onClearInvalid={handleClearError}
          subDescription={<p className={styles.email}>{body.email}</p>}
        />
      ) : (
        displayLoginForm()
      )}

      <div className={styles.buttons}>
        <Button icon={<EmailIcon />} variant="text" onClick={handleLoginViaCodeClick}>
          Log in via Email Code
        </Button>
        <Link className={styles.link} to="/forgot-password">
          Forgot Password?
        </Link>
      </div>
    </BrandCard>
  )
}
