import React from 'react'
import { useParams } from 'react-router-dom'
import { PlatformInvitationType } from '@medentee/enums'

import { Spinner } from 'App/components'

import { NewAccount } from './NewAccount'
import { SignUpByEmailContextProvider, useSignUpByEmailContext } from './SignUpByEmailContext'

type TParams = {
  token: string
}

type TSignUpByEmailProps = {
  platformInvitationType?: PlatformInvitationType
}

export const SignUpByEmailView = () => {
  const { isFetching, shouldRedirect } = useSignUpByEmailContext()

  if (isFetching || shouldRedirect) {
    return <Spinner />
  }

  return <NewAccount />
}

export const SignUpByEmail = ({ platformInvitationType }: TSignUpByEmailProps) => {
  const { token } = useParams<TParams>()

  return (
    <SignUpByEmailContextProvider initialState={{ token, platformInvitationType }}>
      <SignUpByEmailView />
    </SignUpByEmailContextProvider>
  )
}
