import React, { useEffect } from 'react'

import { SECOND_IN_MINUTE } from 'globalConstants'
import { timerFormatted } from 'utils'
import { useCountdown } from 'App/components'

export type TTwoFactorCountdownProps = {
  end: Date
  onTimerStop: (stop: boolean) => void
}

export const TwoFactorCountdown = ({ end, onTimerStop }: TTwoFactorCountdownProps) => {
  const { timeOver, timestamp } = useCountdown(end)

  const hours = Math.floor(timestamp / SECOND_IN_MINUTE / SECOND_IN_MINUTE) % SECOND_IN_MINUTE
  const minutes = Math.floor(timestamp / SECOND_IN_MINUTE) % SECOND_IN_MINUTE
  const seconds = timestamp % SECOND_IN_MINUTE

  useEffect(() => {
    if (timeOver) {
      onTimerStop(timeOver)
    }
  }, [timeOver, onTimerStop])

  return <>{timerFormatted(hours, minutes, seconds)}</>
}
