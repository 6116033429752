import React, { FC, useState } from 'react'

import { IconButton, TextField, TTextFieldProps } from 'App/components'
import { EIconSize } from 'globalConstants'
import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg'
import { ReactComponent as EyeCrossedIcon } from 'assets/icons/EyeCrossed.svg'

import styles from './PasswordField.module.scss'

export type TEyesForFieldProps = Omit<TTextFieldProps, 'InputProps'> | Omit<TTextFieldProps, 'type'>

export const PasswordField: FC<TEyesForFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const toggleShow = () => setShowPassword(!showPassword)

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton
            iconComponent={showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
            iconSize={EIconSize.SM}
            onClick={toggleShow}
            classes={{ root: styles.iconButton }}
          />
        )
      }}
    />
  )
}
