import { useMediaQuery, MediaQueryMatchers } from 'react-responsive'
import { useMemo } from 'react'

import media from 'assets/style/_mediaVariables.module.scss'

export type TUseAdaptiveLayoutReturnProps = {
  customMaxWidth: boolean
  isPortrait: boolean
  isLandscape: boolean
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  resolution: 'desktop' | 'tablet' | 'mobile'
  orientation: MediaQueryMatchers['orientation']
}

export const useAdaptiveLayout = (maxWidth?: string): TUseAdaptiveLayoutReturnProps => {
  const { desktopMinWidth, mobileMaxWidth, mobileMaxHeightLandscape } = media

  const isDesktop = useMediaQuery({ minWidth: Number(desktopMinWidth) + 1 })

  const isMobileDevicePortrait = useMediaQuery({
    maxWidth: Number(mobileMaxWidth),
    orientation: 'portrait'
  })

  const isMobileDeviceLandscape = useMediaQuery({
    maxHeight: Number(mobileMaxHeightLandscape),
    maxWidth: Number(desktopMinWidth),
    orientation: 'landscape'
  })

  const isMobile = isMobileDevicePortrait || isMobileDeviceLandscape
  const isTablet = !isMobile && !isDesktop

  const customMaxWidth = useMediaQuery({
    maxWidth: Number(maxWidth)
  })

  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isLandscape = useMediaQuery({ orientation: 'landscape' })

  const resolution = useMemo<TUseAdaptiveLayoutReturnProps['resolution']>(() => {
    if (isDesktop) {
      return 'desktop'
    }

    if (isTablet) {
      return 'tablet'
    }

    if (isMobile) {
      return 'mobile'
    }

    return 'desktop'
  }, [isDesktop, isMobile, isTablet])

  const orientation = useMemo<TUseAdaptiveLayoutReturnProps['orientation']>(() => {
    if (isLandscape) {
      return 'landscape'
    }

    if (isPortrait) {
      return 'portrait'
    }

    return
  }, [isLandscape, isPortrait])

  return {
    customMaxWidth,
    isPortrait,
    isLandscape,
    isDesktop,
    isTablet,
    isMobile,
    orientation,
    resolution
  }
}
