import { PropsWithChildren, useMemo } from 'react'
import cls from 'classnames'

import { TButtonProps } from 'App/components/common/Button'

import styles from './ButtonBaseContent.module.scss'

export type TButtonBaseContentProps = PropsWithChildren<
  Pick<TButtonProps, 'icon' | 'iconPosition' | 'hideIcon'>
>

export const ButtonBaseContent = ({
  icon,
  children,
  hideIcon,
  iconPosition = 'left'
}: TButtonBaseContentProps) => {
  const iconContent = useMemo(
    () =>
      !hideIcon && icon ? (
        <div className={cls(styles[iconPosition], styles.icon)}>{icon}</div>
      ) : null,
    [hideIcon, icon, iconPosition]
  )

  return (
    <>
      {iconContent ? (
        <div className={styles.wrapper}>
          {iconPosition === 'left' && iconContent}
          {children}
          {iconPosition === 'right' && iconContent}
        </div>
      ) : (
        children
      )}
    </>
  )
}
