import { EContainerId, TToastOptions } from 'App/components/ToastContainer/ToastContainer'

export const redirectKey = 'r_u'
export const downloadAppKey = 'download_app_continue'

export const TERMS_OF_USE_URL = 'https://medentee.com/terms-of-use'

export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.medentee.app.prod'
export const IOS_APP_LINK = 'https://apps.apple.com/app/medentee/id1621528006'
export const DOWNLOAD_MOBILE_APP_PAGE_URL = 'https://medentee.com/app-download'

export const TOAST_AUTO_CLOSE_DELAY = 5000

export const toastDefaultOptions: TToastOptions = {
  containerId: EContainerId.ALERTS,
  autoClose: TOAST_AUTO_CLOSE_DELAY
}

export const deleteKeyCodes = [8, 46]

export enum EIconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export const SECOND = 1000
export const DEFAULT_TIME_HELPER = 50
export const SECOND_IN_MINUTE = 60

export const MAX_LENGTH_254 = 254
