import React from 'react'
import cls from 'classnames'
import { Tooltip as OriginalTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

import styles from './Tooltip.module.scss'

export const Tooltip = ({ placement = 'bottom', overlayClassName = '', ...rest }: TooltipProps) => (
  <OriginalTooltip
    overlayClassName={cls({
      [styles.root]: true,
      [overlayClassName]: !!overlayClassName
    })}
    placement={placement}
    {...rest}
  />
)
