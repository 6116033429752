import React, { useEffect, useMemo } from 'react'
import { Field } from 'react-final-form'
import { FormApi } from 'final-form'

import { createSelectOptions } from 'utils'
import validation from 'utils/validation'
import { TextFieldWithTooltip, Select, CheckBoxField, TextField } from 'App/components'
import { SignUpFormInfo } from 'App/screens/SignUp/SignUpFormInfo'
import { TERMS_OF_USE_URL } from 'globalConstants'

import { useSignUpByEmailContext } from '../SignUpByEmailContext'

export type TSignUpByEmailUserDataFieldsProps = {
  form: FormApi
  jointToCommunity: boolean
}

const MAX_LENGTH = 50
const MAX_LENGTH_120 = 120
const TOOLTIP_TEXT = 'Numbers and some special symbols are prohibited'

export const SignUpByEmailUserDataFields = ({ form }: TSignUpByEmailUserDataFieldsProps) => {
  const { data, cities, countries, professions, fetchCities } = useSignUpByEmailContext()

  const email = data?.invitation?.email ?? data?.email

  const isCityDisabled = !form.getFieldState('country')?.value
  const values = form.getState().values

  const cityOptions = useMemo(
    () => cities && createSelectOptions({ options: cities, labelKey: 'cityName', valueKey: 'id' }),
    [cities]
  )
  const countryOptions = useMemo(
    () =>
      countries &&
      createSelectOptions({ options: countries, labelKey: 'countryName', valueKey: 'code' }),
    [countries]
  )
  const profession = useMemo(() => {
    let otherProfId = ''
    const professionOptions =
      professions &&
      createSelectOptions({
        options: professions.map(({ withSpecializations, ...rest }) => {
          if (withSpecializations) {
            otherProfId = rest.id
          }
          return { ...rest }
        }),
        labelKey: 'name',
        valueKey: 'id'
      })

    return {
      otherProfId,
      professionOptions
    }
  }, [professions])

  const infoItems = useMemo(
    () => [
      {
        label: 'Email',
        value: email ?? ''
      }
    ],
    [email]
  )

  useEffect(() => {
    if (values.country.value) {
      fetchCities(String(values.country.value))
    }
  }, [values.country])

  return (
    <>
      <SignUpFormInfo items={infoItems} />

      <Field
        name="firstName"
        validate={validation.composeValidators(
          validation.required(),
          validation.isPersonName('name'),
          validation.maxLength(MAX_LENGTH)
        )}
      >
        {({ input, meta: { error, touched, invalid } }) => (
          <TextFieldWithTooltip
            {...input}
            tooltip={TOOLTIP_TEXT}
            label="First Name*"
            valueLengthMax={MAX_LENGTH}
            invalid={touched && invalid}
            error={error}
          />
        )}
      </Field>
      <Field
        name="lastName"
        validate={validation.composeValidators(
          validation.required(),
          validation.isPersonName('lastName'),
          validation.maxLength(MAX_LENGTH)
        )}
      >
        {({ input, meta: { error, touched, invalid } }) => (
          <TextFieldWithTooltip
            {...input}
            tooltip={TOOLTIP_TEXT}
            label="Last Name*"
            valueLengthMax={MAX_LENGTH}
            invalid={touched && invalid}
            error={error}
          />
        )}
      </Field>

      <Field name="profession" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            onChange={(v) => {
              form.change(input.name, v)
            }}
            topLabel="Profession*"
            errorMessage={meta.error}
            invalid={meta.invalid && meta.submitFailed}
            options={profession.professionOptions}
          />
        )}
      </Field>
      {values.profession?.value === profession.otherProfId && (
        <Field
          name="specialization"
          validate={validation.composeValidators(
            validation.onlySpaces(),
            validation.maxLength(MAX_LENGTH_120)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel="Specialization"
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              valueLengthMax={MAX_LENGTH_120}
            />
          )}
        </Field>
      )}
      <Field name="country" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            onChange={(v) => {
              form.change('city', undefined)
              form.change(input.name, v)
              fetchCities(v.value)
            }}
            topLabel="Country*"
            errorMessage={meta.error}
            invalid={meta.invalid && meta.submitFailed}
            options={countryOptions}
          />
        )}
      </Field>
      <Field name="city" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            topLabel="City*"
            errorMessage={meta.error}
            invalid={meta.invalid && meta.submitFailed}
            disabled={isCityDisabled}
            options={cityOptions}
          />
        )}
      </Field>

      <Field name="confirm" component="input" type="checkbox" validate={validation.required()}>
        {({ input, meta: { visited } }) => {
          const error = visited && !form.getState().values.confirm

          return (
            <CheckBoxField
              {...input}
              error={error}
              id="confirm-name"
              label="I confirm correctness of my Full Name*"
            />
          )
        }}
      </Field>

      <Field name="terms" component="input" type="checkbox" validate={validation.required()}>
        {({ input, meta: { visited } }) => {
          const error = visited && !form.getState().values.terms

          return (
            <CheckBoxField
              {...input}
              id="privacy-policy"
              error={error}
              label={
                <>
                  I agree with{' '}
                  <a rel="noopener noreferrer" target="_blank" href={TERMS_OF_USE_URL}>
                    Terms of Use
                  </a>{' '}
                  *
                </>
              }
            />
          )
        }}
      </Field>

      {/* This is hidden due to this task https://edento.atlassian.net/browse/MED-7858 */}
      {/* {jointToCommunity && <JointToCommunityField />} */}
    </>
  )
}
