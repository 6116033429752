import axios from 'axios'

import config from 'config'

export const API = {
  API_URL: `${config.apiUrl}`,
  API_AUTH_URL: `${config.apiAuthUrl}`,
  CITIES: '/misc/cities',
  COUNTRIES: '/misc/countries',
  PROFESSIONS: '/misc/professions/v2',
  LOGIN: '/auth/login',
  LOGIN_VIA_CODE: '/auth/login-via-code',
  FORGOT_PASSWORD: '/accounts/forgot-password',
  FORGOT_PASSWORD_VERIFY_TOKEN: '/accounts/verify-reset-password-token',
  REGISTRATION: (token: string) => `/invitations/by-email/${token}/registration`,
  PRE_REGISTRATION: (token: string) => `/invitations/by-email/${token}/pre-registration`,
  CONTINUE_VIA_JWT: (token: string) => `/invitations/by-link/${token}/continue-via-jwt`,
  CHECK_SESSION: '/auth/check-session',
  LOCATION: '/misc/predicted-location'
}
// Set config defaults when creating the instance
export const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})
