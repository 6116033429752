import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { TError } from 'types'
import { ECodeErrors } from 'enums'
import { acceptInvitation } from 'api/signup'
import { handleError, isMatchErrorCode } from 'utils'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'
import { authorizeViaCode } from 'api/auth'

export const useAcceptInviteRequest = (email?: string) => {
  const history = useHistory()

  const { mutate: sendCode } = useMutation(['send-code'], authorizeViaCode)

  const mutation = useMutation(['accept-invitation'], acceptInvitation, {
    onSuccess: () => {
      const searchQuery = queryString.stringify({ email })

      sendCode({ values: { email } })

      toast.success('Successfully registered!', toastDefaultOptions)
      history.replace(`${LOGIN_VIA_CODE_URL}?${searchQuery}`)
    },
    onError: (e: TError) => {
      handleError(e)
      if (isMatchErrorCode(e, ECodeErrors.USER_INVITE_BAD_STATUS)) {
        history.push(LOGIN_VIA_CODE_URL)
      }
    }
  })
  return mutation
}
