import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import cls from 'classnames'

import { TwoFactorCodeGroupe, Spinner, usePrevious, TwoFactorTimer, Button } from 'App/components'

import styles from './TwoFactorChecker.module.scss'

export type TTwoFactorCheckerProps = {
  expiredTokenDate: null | string | Date
  onResendCode: () => void
  onConfirm: (code: string) => void
  processing: boolean

  onClearInvalid?: () => void
  invalid?: boolean
  codeExhausted?: boolean
  subDescription?: ReactNode
}

export const TwoFactorChecker = ({
  invalid = false,
  expiredTokenDate,
  onResendCode,
  onConfirm,
  onClearInvalid,
  processing,
  codeExhausted,
  subDescription
}: TTwoFactorCheckerProps) => {
  const [code, setCode] = useState<string>('')
  const [filledCode, setFilledCode] = useState<boolean>(false)
  const [timeOver, setTimeOver] = useState<boolean>(false)
  const prevCode = usePrevious(code)

  const handleChangeCode = useCallback((value: string, filled: boolean) => {
    setCode(value)
    setFilledCode(filled)
  }, [])

  const handleConfirm = useCallback(() => {
    if (code) {
      onConfirm(code)
    }
  }, [code, onConfirm])

  const handleResendCode = useCallback(() => {
    onResendCode()
    setCode('')
    setTimeOver(false)
  }, [onResendCode])

  useEffect(() => {
    if (onClearInvalid && invalid && prevCode !== code) {
      onClearInvalid()
    }
  }, [onClearInvalid, code, prevCode, invalid])

  const disabled = useMemo(() => {
    if (timeOver || !code || !filledCode || invalid || codeExhausted || !expiredTokenDate) {
      return true
    }

    if (!timeOver && filledCode) {
      return false
    }

    return false
  }, [code, expiredTokenDate, filledCode, invalid, timeOver, codeExhausted])

  return (
    <div className={styles.root}>
      <p className={cls(styles.description, subDescription && styles.hasSubDescription)}>
        {subDescription
          ? 'Enter 6-digit verification code from your email:'
          : 'Please enter 6-digit verification code from your email.'}
      </p>
      {subDescription}

      <TwoFactorTimer
        expiredTokenDate={expiredTokenDate}
        processing={processing}
        setTimeOver={setTimeOver}
        timeOver={timeOver}
        codeExhausted={codeExhausted}
      />

      <div className={styles.code}>
        <TwoFactorCodeGroupe invalid={invalid} onChange={handleChangeCode} value={code} />
      </div>

      <div className={styles.resend}>
        {processing ? (
          <Spinner />
        ) : (
          <button
            tabIndex={7}
            type="button"
            className={styles.buttonLink}
            onClick={handleResendCode}
          >
            Resend confirmation code
          </button>
        )}
      </div>

      <Button
        className={styles.button}
        tabIndex={6}
        onClick={handleConfirm}
        disabled={disabled || processing}
      >
        Confirm
      </Button>
    </div>
  )
}
