import { useLayoutEffect } from 'react'
import { PlatformInvitationType } from '@medentee/enums'

import config from 'config'
import { redirectKey } from 'globalConstants'
import { useSessionExist } from 'App/components/hooks'
import { getMapComponent } from 'utils'

export type TUseSignUpByLinkRequests = {
  token: string

  platformInvitationType?: PlatformInvitationType
}

const URI_MAP = new Map<PlatformInvitationType, () => string>()
  .set(PlatformInvitationType.EVENT, () => 'events/t/info')
  .set(PlatformInvitationType.COMMUNITY, () => 'communities/t/info')

export const useSignUpByLinkRedirect = ({
  token,
  platformInvitationType
}: TUseSignUpByLinkRequests) => {
  const { sessionExist, shouldShowLoading } = useSessionExist()

  useLayoutEffect(() => {
    const redirectPath = getMapComponent(URI_MAP, platformInvitationType) ?? 'profile/t'
    const redirectUrl = `${config.defaultRedirectUrl}/${redirectPath}/${token}`

    if (sessionExist && token) {
      window.sessionStorage.removeItem(redirectKey)
      window.location.href = redirectUrl
    }

    if (!sessionExist && token) {
      window.sessionStorage.setItem(redirectKey, redirectUrl)
    }
  }, [sessionExist, token, platformInvitationType])

  return {
    shouldShowLoading
  }
}
