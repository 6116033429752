import { useRef, useLayoutEffect, useMemo, useCallback } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import isArray from 'lodash/isArray'

import config from 'config'
import { getDeviceInfo, getMapComponent } from 'utils'
import { ANDROID_APP_LINK, IOS_APP_LINK } from 'globalConstants'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'
import { Button } from 'App/components'
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg'
import { ReactComponent as AppleIcon } from 'assets/icons/Apple.svg'
import { ReactComponent as GoogleIcon } from 'assets/icons/Google.svg'
import { ReactComponent as CellPhoneIcon } from 'assets/icons/CellPhone.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'

import styles from './DeepLinkFallback.module.scss'

export type TDeepLinkFallbackContentVariant = 'default' | 'confirm_email'

const CONTENT_MAP = new Map<TDeepLinkFallbackContentVariant, () => JSX.Element>()
  .set('default', () => (
    <>
      <CellPhoneIcon className={styles.icon} />
      <h2 className={styles.title}>You need to have an app to use your invitation</h2>
    </>
  ))
  .set('confirm_email', () => (
    <>
      <EmailIcon className={styles.icon} />
      <h2 className={styles.title}>Email verified</h2>
      <p className={styles.text}>
        To complete your registration,
        <br />
        go to the App
      </p>
    </>
  ))

export const DeepLinkFallback = () => {
  const isMetaMounted = useRef(false)
  const { search } = useLocation()

  const { isAndroid, isIOS, isMobile } = getDeviceInfo()
  const showForMobile = isMobile && (isAndroid || isIOS)

  const data = useMemo<Record<string, string> | null>(() => {
    const parsed = parse(search)
    const pathname = isArray(parsed.pathname) ? parsed.pathname[0] : parsed.pathname
    const contentType = isArray(parsed.contentType) ? parsed.contentType[0] : parsed.contentType

    if (!parsed || !pathname) {
      return null
    }

    return {
      pathname,
      contentType: contentType ?? 'default',
      deepLink: `${config.deepLinkPrefix}://${window.location.hostname}${pathname}`
    }
  }, [search])

  const { deepLink, pathname, contentType } = data ?? {}

  const handelClick = useCallback(() => {
    if (isIOS) {
      window.location.href = IOS_APP_LINK
    } else if (isAndroid) {
      window.location.href = ANDROID_APP_LINK
    } else {
      window.location.href = deepLink
    }
  }, [deepLink, isIOS, isAndroid])

  useLayoutEffect(() => {
    let meta: HTMLMetaElement | null = null

    if (deepLink && !isMetaMounted.current) {
      meta = document.createElement('meta')
      meta.httpEquiv = 'refresh'
      meta.content = `0; url=${deepLink}`

      document.head.appendChild(meta)
      isMetaMounted.current = true
    }

    return () => {
      if (meta) {
        document.head.removeChild(meta)
      }
    }
  }, [deepLink, search])

  if (!showForMobile) {
    return <Redirect to={pathname ? pathname : LOGIN_VIA_CODE_URL} />
  }

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <LogoIcon className={styles.logo} />
      </header>

      <div className={styles.card}>
        <div className={styles.content}>
          {getMapComponent(CONTENT_MAP, contentType)}

          <Button className={styles.button} onClick={handelClick}>
            Open App
          </Button>

          <div className={styles.dividerContainer}>
            <hr className={styles.divider} />
            <span className={styles.orText}>or</span>
            <hr className={styles.divider} />
          </div>
        </div>

        <div className={styles.download}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={IOS_APP_LINK}
            className={styles.downloadButton}
          >
            <AppleIcon className={styles.appleIcon} />
            <p className={styles.downloadText}>Download from AppStore</p>
          </a>

          <a
            rel="noopener noreferrer"
            target="_blank"
            href={ANDROID_APP_LINK}
            className={styles.downloadButton}
          >
            <GoogleIcon className={styles.googleIcon} />
            <p className={styles.downloadText}>Download from Google Play</p>
          </a>
        </div>
      </div>
    </div>
  )
}
