import { TOption } from 'App/components'

type TCreateSelectOptionsPayload<Options extends Record<string, unknown>> = {
  options: Options[]
  labelKey: keyof Options
  valueKey: keyof Options
}
type TCreateSelectOptionPayload<Options extends Record<string, unknown>> = {
  option: Options
  labelKey: keyof Options
  valueKey: keyof Options
}

export const createSelectOption = <Options extends Record<string, string | number>>({
  option,
  labelKey,
  valueKey
}: TCreateSelectOptionPayload<Options>) => ({
  label: option[labelKey] as string,
  value: option[valueKey]
})

export const createSelectOptions = <Options extends Record<string, string | number>>({
  options,
  labelKey,
  valueKey
}: TCreateSelectOptionsPayload<Options>) =>
  options.map<TOption>((option) => createSelectOption({ option, labelKey, valueKey }))
