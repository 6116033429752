import cls from 'classnames'

import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'

import styles from './ModalIcon.module.scss'

export type TModalIconProps = {
  type: 'error'
}
export const ModalIcon = (_: TModalIconProps) => (
  <CloseCircleOutlinedIcon className={cls(styles.root, styles.error)} />
)
