import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'

import { ButtonPrevNext, Steps, StepsComponentProps } from 'App/components/common'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

import { useAdaptiveLayout } from '../hooks'

import styles from './BrandCardHeader.module.scss'

export type TBrandCardProps = {
  title: ReactNode
  step?: number
  showSteps?: boolean
  onBack?: () => void
  showLoginLink?: boolean
} & Partial<Pick<StepsComponentProps, 'stepsArray'>>

export const BrandCardHeader: FC<TBrandCardProps> = ({
  title,
  stepsArray,
  step = 0,
  showLoginLink = false,
  showSteps = false,
  onBack
}) => {
  const { isMobile } = useAdaptiveLayout()

  return (
    <div
      className={cls({
        [styles.root]: true,
        [styles.rootWithStep]: showSteps && !!step
      })}
    >
      <header className={styles.header}>
        {onBack && !isMobile && (
          <ButtonPrevNext
            type="prev"
            onClick={onBack}
            buttonProps={{ type: 'button', className: styles.backButton }}
          />
        )}
        <h1 className={styles.title}>{title}</h1>
      </header>

      {showLoginLink && (
        <div className={styles.login}>
          Already have an account?
          <Link className={styles.link} to={LOGIN_VIA_CODE_URL}>
            Log In
          </Link>
        </div>
      )}

      {showSteps && stepsArray && (
        <Steps className={styles.steps} current={step} stepsArray={stepsArray} />
      )}
    </div>
  )
}
