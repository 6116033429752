import { FC, useRef, useEffect } from 'react'
import { ModalProps } from 'antd/lib/modal'
import { Modal as ModalOriginal } from 'antd'
import cls from 'classnames'

import { useAdaptiveLayout } from 'App/components/hooks'
import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg'
import styleVariables from 'assets/style/_mediaVariables.module.scss'

import styles from './Modal.module.scss'
import { ModalIcon, TModalIconProps } from './ModalIcon'

export type TModalWidth = number | 'unset' | 'auto'

type TPreventDefaultProps = PointerEvent | TouchEvent

export type TModalProps = Omit<ModalProps, 'width'> & {
  width?: TModalWidth
  icon?: TModalIconProps['type']
}

const zIndexParsed = parseInt(styleVariables.zIndexModal)
export const Z_INDEX = isNaN(zIndexParsed) ? 3090 : zIndexParsed

const preventDefault = (e: TPreventDefaultProps) => {
  e.preventDefault()
}

const syncHeight = () => {
  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
}

const IS_LOCKED_HTML_CLASS = 'is-locked'

export const Modal: FC<TModalProps> = ({ width, className = '', icon, title, ...rest }) => {
  const scrollYRef = useRef<null | number>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const { isDesktop } = useAdaptiveLayout()

  useEffect(() => {
    if (!isDesktop) {
      scrollYRef.current = window.scrollY

      document.documentElement.classList.add(IS_LOCKED_HTML_CLASS)
      syncHeight()
      window.addEventListener('resize', syncHeight)

      if (modalRef.current) {
        // block pointer events for IOS 15
        modalRef.current.addEventListener('pointermove', preventDefault)
        // block pointer events for IOS < 15
        modalRef.current.addEventListener('touchmove', preventDefault)
      }
    }

    return () => {
      document.documentElement.classList.remove(IS_LOCKED_HTML_CLASS)
      window.scrollTo(0, scrollYRef?.current || 0)

      window.removeEventListener('resize', syncHeight)
      if (modalRef.current) {
        modalRef.current.removeEventListener('pointermove', preventDefault)
        modalRef.current.removeEventListener('touchmove', preventDefault)
      }
    }
  }, [])

  return (
    <div ref={modalRef}>
      <ModalOriginal
        centered={true}
        zIndex={Z_INDEX}
        transitionName=""
        className={cls({
          [styles.root]: true,
          [className]: !!className
        })}
        closeIcon={<CloseIcon className={styles.closeIcon} />}
        width={width}
        title={
          icon ? (
            <>
              {<ModalIcon type={icon} />}
              {title}
            </>
          ) : (
            title
          )
        }
        {...rest}
      />
    </div>
  )
}
