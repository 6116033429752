import { useQuery } from '@tanstack/react-query'
import React, {
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect
} from 'react'
import { useHistory } from 'react-router-dom'
import { PlatformInvitationType } from '@medentee/enums'

import {
  getCities,
  getCountries,
  getDataByEmailToken,
  getProfessions,
  TCity,
  TCountry,
  TPreRegistrationDataDTO,
  TProfession
} from 'api/signup'
import { ECodeErrors } from 'enums'
import { getMapComponent, handleError, isMatchErrorCode, parseJwt } from 'utils'
import { TError } from 'types'
import { redirectKey } from 'globalConstants'
import config from 'config'
import { useSessionExist } from 'App/components'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

type TContextProps = {
  token: string
  isFetching: boolean
  shouldRedirect: boolean
  isAssociateType: boolean
  fetchCities: (name: string) => void

  data?: TPreRegistrationDataDTO
  cities?: TCity[]
  countries?: TCountry[]
  professions?: TProfession[]
}

type TInitialState = {
  token: string

  platformInvitationType?: PlatformInvitationType
}

export type TSignUpByEmailContextProviderProps = {
  children: ReactNode
  initialState: TInitialState
}

const SignUpByEmailContext = createContext<TContextProps>({} as TContextProps)

const URI_MAP = new Map<PlatformInvitationType, () => string>()
  .set(PlatformInvitationType.EVENT, () => 'events/t/info')
  .set(PlatformInvitationType.COMMUNITY, () => 'communities/t/info')

export const useSignUpByEmailContext = () => {
  const context = useContext(SignUpByEmailContext)

  if (context === undefined) {
    throw new Error('useSignUpByEmailContext must be used within a SignUpByEmailContextProvider')
  }

  return context
}

const getRedirectUrl = (token: string, type?: PlatformInvitationType) => {
  const redirectPath = getMapComponent(URI_MAP, type) ?? 'profile/t'

  return `${config.defaultRedirectUrl}/${redirectPath}/${token}`
}

export const SignUpByEmailContextProvider = ({
  children,
  initialState
}: TSignUpByEmailContextProviderProps) => {
  const history = useHistory()
  const [countryCode, setCountryCode] = useState<string | undefined>()
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const { sessionExist, sessionResponse } = useSessionExist()
  const { token, platformInvitationType } = initialState

  const { data, isFetching } = useQuery(
    ['get-data-by-email-token', token],
    () => getDataByEmailToken(token),
    {
      enabled: sessionResponse.isFetched,
      cacheTime: 0,
      onSuccess: ({ invitation, account }) => {
        if (!invitation && !account) {
          history.replace(LOGIN_VIA_CODE_URL)
        }
      },
      onError: (e: TError) => {
        if (
          platformInvitationType === PlatformInvitationType.EVENT &&
          isMatchErrorCode(e, ECodeErrors.LOG_INTO_YOUR_ACCOUNT)
        ) {
          const parsedToken = parseJwt(token)?.token

          if (sessionExist && parsedToken) {
            setShouldRedirect(true)

            window.location.href = getRedirectUrl(parsedToken, PlatformInvitationType.EVENT)
            return
          }

          if (!sessionExist && parsedToken) {
            window.sessionStorage.setItem(
              redirectKey,
              getRedirectUrl(parsedToken, PlatformInvitationType.EVENT)
            )
          }
        }

        handleError(e)

        if (
          isMatchErrorCode(e, ECodeErrors.TOKEN_INVALID) ||
          isMatchErrorCode(e, ECodeErrors.VALIDATION_FAILED) ||
          isMatchErrorCode(e, ECodeErrors.USER_INVITE_EXPIRED) ||
          isMatchErrorCode(e, ECodeErrors.ACCOUNT_EXISTS) ||
          isMatchErrorCode(e, ECodeErrors.LOG_INTO_YOUR_ACCOUNT) ||
          isMatchErrorCode(e, ECodeErrors.INVITATION_NOT_EXIST) ||
          isMatchErrorCode(e, ECodeErrors.INVITATION_EXPIRED)
        ) {
          history.push(LOGIN_VIA_CODE_URL)
        }
      }
    }
  )

  const isAssociateType = data?.invitation?.type === PlatformInvitationType.ASSOCIATE

  const { data: professions, isFetching: isProfessionsFetching } = useQuery(
    ['get-professions'],
    getProfessions
  )

  const { data: countries, isFetching: isCountriesFetching } = useQuery(
    ['get-countries'],
    getCountries
  )

  const { data: cities } = useQuery(['get-cities', countryCode], () => getCities(countryCode), {
    enabled: Boolean(countryCode)
  })

  const fetchCities = useCallback((code: string) => {
    setCountryCode(code)
  }, [])

  useEffect(() => {
    if (
      data?.invitation?.token &&
      (!data.invitation.email || data.invitation.type === PlatformInvitationType.EVENT)
    ) {
      window.sessionStorage.setItem(
        redirectKey,
        getRedirectUrl(data.invitation.token, data.invitation.type)
      )
    }
  }, [data])

  return (
    <SignUpByEmailContext.Provider
      value={{
        token,
        data,
        isFetching:
          isFetching || isProfessionsFetching || isCountriesFetching || sessionResponse.isFetching,
        isAssociateType,
        professions,
        cities,
        countries,
        fetchCities,
        shouldRedirect
      }}
    >
      {children}
    </SignUpByEmailContext.Provider>
  )
}
