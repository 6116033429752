import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import cls from 'classnames'
import Highlighter from 'react-highlight-words'
import ReactSelect, { components as rComponents } from 'react-select'
import { MenuProps, MenuListComponentProps } from 'react-select/src/components/Menu'
import { InputProps } from 'react-select/src/components/Input'
import { IndicatorProps } from 'react-select/src/components/indicators'
import { ControlProps } from 'react-select/src/components/Control'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { MultiValueProps } from 'react-select/src/components/MultiValue'
import { OptionProps } from 'react-select/src/components/Option'
import { OptionTypeBase } from 'react-select/src/types'

import { deleteKeyCodes } from 'globalConstants'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'

import styles from './Select.module.scss'

const Control = (controlProps: ControlProps<TOption>) => (
  <rComponents.Control
    {...controlProps}
    className={cls({
      [styles.control]: true,
      [styles.focused]: controlProps.isFocused,
      [styles.selected]: controlProps.hasValue,
      [styles.isMulti]: controlProps.isMulti
    })}
  >
    {controlProps.selectProps.label && (
      <label className={styles.label}>{controlProps.selectProps.label}</label>
    )}
    <div className={styles.baseBorder}>{controlProps.children}</div>
  </rComponents.Control>
)

const Option = (props: OptionProps<TOption>) => (
  <rComponents.Option
    {...props}
    className={cls({
      [styles.option]: true,
      [styles.optionSelected]: props.isSelected,
      [styles.optionFocused]: props.isFocused
    })}
  >
    {props.children}
  </rComponents.Option>
)

const MultiValue = (props: MultiValueProps<TOption>) => (
  <rComponents.MultiValue {...props} className={styles.multiValue}>
    {props.selectProps.originalValue ? props.data.value : props.children}
  </rComponents.MultiValue>
)

const SingleValue = (props: SingleValueProps<TOption>) => (
  <rComponents.SingleValue {...props} className={styles.singleValue}>
    {props.selectProps.originalValue ? props.data.value : props.children}
  </rComponents.SingleValue>
)

const ValueContainer = (props: ValueContainerProps<TOption>) => (
  <rComponents.ValueContainer {...props} className={styles.value} />
)

const Placeholder = (props: PlaceholderProps<TOption>) => (
  <rComponents.Placeholder {...props} className={styles.placeholder} />
)

const DropdownIndicator = (props: IndicatorProps<TOption>) => (
  <rComponents.DropdownIndicator {...props} className={styles.indicator}>
    <ChevronDownIcon />
  </rComponents.DropdownIndicator>
)

const IndicatorSeparator = () => null

const Input = (inputProps: InputProps) => (
  <div className={styles.inputWrapper}>
    <rComponents.Input {...inputProps} />
  </div>
)

const Menu = (menuProps: MenuProps<TOption>) => (
  <rComponents.Menu
    {...menuProps}
    className={cls({
      [styles.menu]: true,
      [menuProps.selectProps.classNameMenu]: true
    })}
  />
)

const MenuList = (menuProps: MenuListComponentProps<TOption>) => (
  <rComponents.MenuList
    {...menuProps}
    className={cls({
      [styles.menuList]: true,
      [menuProps.selectProps.classNameMenuList]: true
    })}
  />
)

const NoOptionsMessage = () => (
  <div className={styles.noOptionMessage}>No result found. Please, reset research.</div>
)

const components: Partial<typeof rComponents> = {
  Option,
  MultiValue,
  SingleValue,
  Placeholder,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator,
  Input,
  Menu,
  MenuList,
  Control,
  NoOptionsMessage
}

type THighlightTagProps = {
  children: string | React.ComponentType<any>
}

export type TOption = {
  value: string | number
  label: string
}

export type TOptionProfession = {
  value: string | number
  label: string
  withSpecializations: boolean
}

export type TComboBoxProps = {
  wrapperClassName?: string
  topLabel?: ReactNode | string | null

  label?: string
  errorMessage?: string

  id?: string
  readOnly?: boolean
  isMulti?: boolean
  originalValue?: boolean
  value?: any | any[] | null
  options?: any[]
  invalid?: boolean
  disabled?: boolean
  onChange: (v: any) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  classNameMenuList?: string
  classNameMenu?: string
  defaultOptions?: any[]
  isSmall?: boolean
  isClearable?: boolean
  labelKey?: string
  valueKey?: string
}

export const Select: FC<TComboBoxProps> = (props) => {
  const {
    disabled: isDisabled,
    invalid,
    defaultOptions,
    onChange,
    topLabel,
    onKeyDown,
    options,
    isMulti = false,
    isSmall = false,
    isClearable = false,
    labelKey = 'label',
    valueKey = 'value',
    errorMessage = '',
    wrapperClassName = '',
    ...rest
  } = props
  const [optionsState, setOptionsState] = useState<any[]>(options || [])

  useEffect(() => {
    setOptionsState(options || [])
  }, [options])

  const formatOptionLabel = (option: any, { inputValue }: OptionTypeBase) => {
    const getHighlightTag = ({ children }: THighlightTagProps) => (
      <span className={styles.highlightText}>{children}</span>
    )

    return (
      <Highlighter
        searchWords={[inputValue.trim()]}
        textToHighlight={option[labelKey]}
        highlightTag={getHighlightTag}
        autoEscape={true}
      />
    )
  }

  const onInputChange = useCallback(
    (value: string) => {
      if (!options) {
        return
      }

      const formattedValue = value.trim().toLowerCase()
      const newOptionsState = options.filter(
        (item) => item[labelKey].toLowerCase().indexOf(formattedValue) >= 0
      )

      newOptionsState.sort((a, b) => {
        const indexOfA = a[labelKey].toLowerCase().indexOf(formattedValue)
        const indexOfB = b[labelKey].toLowerCase().indexOf(formattedValue)

        return indexOfA - indexOfB
      })

      setOptionsState(newOptionsState)
    },
    [labelKey, options]
  )

  const handleChange = useCallback(
    (value) => {
      onChange(isMulti ? value || [] : value)
    },
    [onChange, isMulti]
  )

  const onKeyDownEvent = (event: React.KeyboardEvent<HTMLElement>) => {
    onKeyDown && onKeyDown(event)

    if (isClearable && deleteKeyCodes.includes(event.keyCode)) {
      !isMulti && onChange(null)
    }
  }

  const getOptionLabel = (option: any) => option[labelKey]
  const getOptionValue = (option: any) => option[valueKey]

  return (
    <div
      className={cls({
        [styles.wrapper]: true,
        [wrapperClassName]: !!wrapperClassName
      })}
    >
      {topLabel && <div className={styles.topLabel}>{topLabel}</div>}
      <ReactSelect
        components={components}
        options={optionsState}
        {...rest}
        invalid={invalid}
        errorMessage={errorMessage}
        isDisabled={isDisabled}
        className={cls({
          [styles.root]: !isSmall,
          [styles.smallSelect]: isSmall,
          [styles.rootDisable]: isDisabled
        })}
        onChange={handleChange}
        placeholder={null}
        formatOptionLabel={formatOptionLabel}
        onKeyDown={onKeyDownEvent}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    </div>
  )
}
