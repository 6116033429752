import { API, api } from '../services/api'
import { getCityWithTimezone } from '../utils/utils'

import { TCity, TCountry } from './signup'

export type TUserLocation = {
  city: TCity
  country: TCountry
}

export const getUserLocation = async () => {
  const { data } = await api.get<TUserLocation>(`${API.API_URL}${API.LOCATION}`)

  return {
    ...data,
    city: getCityWithTimezone(data.city)
  }
}
