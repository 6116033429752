import { useCallback, useMemo } from 'react'
import { Col, Row } from 'antd'

import { ConfirmationModal } from 'App/components/common/ConfirmationModal'
import { Button } from 'App/components/common/Button'
import { getDeviceInfo } from 'utils'
import {
  ANDROID_APP_LINK,
  DOWNLOAD_MOBILE_APP_PAGE_URL,
  IOS_APP_LINK,
  downloadAppKey
} from 'globalConstants'
import { useModalRootContext } from 'App/components/ModalRoot'

export type TDownloadMobileAppDialogProps = {
  onDownload: () => void
  onContinue: () => void
  onClose: () => void
}

export const DownloadMobileAppDialog = ({
  onDownload,
  onContinue
}: TDownloadMobileAppDialogProps) => {
  const { hideModal } = useModalRootContext()

  const url = useMemo(() => {
    const { isAndroid, isIOS } = getDeviceInfo()

    if (isAndroid) {
      return ANDROID_APP_LINK
    }

    if (isIOS) {
      return IOS_APP_LINK
    }

    return DOWNLOAD_MOBILE_APP_PAGE_URL
  }, [])

  const handleDownload = useCallback(() => {
    hideModal()
    onDownload && onDownload()
    window.open(url, '_blank')
  }, [hideModal, onDownload, url])

  const handleContinue = useCallback(() => {
    window.localStorage.setItem(downloadAppKey, 'true')
    hideModal()
    onContinue && onContinue()
  }, [hideModal, onContinue])

  return (
    <ConfirmationModal
      content="To use this link-invitation you need to install Mobile Application."
      controls={
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Button onClick={handleDownload}>Download App</Button>
          </Col>
          <Col span={24}>
            <Button variant="underlined" onClick={handleContinue}>
              Continue web
            </Button>
          </Col>
        </Row>
      }
    />
  )
}
