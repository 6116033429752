import React from 'react'
import cls from 'classnames'

import { Checkbox } from '../Checkbox'

import styles from './CheckBoxField.module.scss'

type TCheckboxClasses = 'wrapper' | 'label' | 'root'

export type TCheckBoxFieldProps = {
  id: string
  label?: string | JSX.Element
  classes?: Partial<Record<TCheckboxClasses, string>>
  error?: boolean
}

export const CheckBoxField = ({ label, classes, error, id, ...rest }: TCheckBoxFieldProps) => (
  <div className={styles.checkbox}>
    <Checkbox
      color="primary"
      id={id}
      {...rest}
      classes={{ root: cls(error && styles.checkboxError) }}
    />
    <label htmlFor={id} className={styles.checkboxLabel}>
      {label}
    </label>
  </div>
)
