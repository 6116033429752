import qs, { StringifyOptions } from 'query-string'

export class QueryBuilder {
  protected url: string

  constructor(url: string) {
    this.url = url
  }

  public build() {
    return this.hasLastQuestionMark() ? this.url.replace('?', '') : this.url
  }

  public stringify(
    object: Record<string, unknown>,
    options: StringifyOptions = { arrayFormat: 'bracket' }
  ) {
    const query = qs.stringify(object, options)

    if (query) {
      this.url += `${this.separator}${query}`
    }

    return this
  }

  public search(searchValue: string, searchType = 'name') {
    if (searchValue) {
      this.url += `${this.separator}search[${searchType}]=${searchValue}`
    }

    return this
  }

  public searchQuery(searchQuery = '') {
    if (searchQuery) {
      this.url += `${this.separator}searchQuery=${encodeURIComponent(searchQuery)}`
    }

    return this
  }

  public searchBy(searchBy: string | number = '') {
    if (searchBy) {
      this.url += `${this.separator}searchBy=${searchBy}`
    }

    return this
  }

  public page(page: string | number = 0) {
    if (page) {
      this.url += `${this.separator}page=${page}`
    }

    return this
  }

  public multiSearch<T extends string>(searchName: string, searchArray?: T[]) {
    if (searchArray) {
      for (const searchString of searchArray) {
        this.url += `${this.separator}${searchName}[]=${searchString}`
      }
    }

    return this
  }

  public showBy(showBy?: string | number) {
    if (showBy) {
      this.url += `${this.separator}showBy=${showBy}`
    }

    return this
  }

  public sortBy<T extends string>(sortBy: T): QueryBuilder {
    this.url += `${this.separator}sortBy=${sortBy}`

    return this
  }

  public custom(key?: string, value?: string | number | boolean) {
    if (key && value) {
      this.url += `${this.separator}${key}=${value}`
    }

    return this
  }

  protected hasQuestionMark() {
    return this.url.includes('?')
  }

  protected hasLastQuestionMark() {
    return this.url[this.url.length - 1] === '?'
  }

  protected get separator() {
    return this.hasQuestionMark() ? '&' : '?'
  }
}
