import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FormApi } from 'final-form'
import { SyntheticEvent } from 'react'

export * from './errorParsing'
export * from './keyboard'
export * from './QueryBuilder'
export * from './formatters'
export * from './detectDevice'

export { default as validation } from './validation'

export const isFormDisabled = (form: FormApi, fields: string[]) => {
  const getBoolean = (arr: string[], obj: { [key: string]: any }) =>
    arr.map((item) => !obj[item]).some((predicate) => predicate)
  const { values, valid } = form.getState()

  const disabled = getBoolean(fields, values)

  return !valid || disabled
}

export const timerFormatted = (hours: number, minutes: number, seconds: number) => {
  const hoursFormatted = `${hours < 10 ? '0' : ''}${hours}`
  const minutesFormatted = `${minutes < 10 ? '0' : ''}${minutes}`
  const secondsFormatted = `${seconds < 10 ? '0' : ''}${seconds}`

  return `${hours > 0 ? hoursFormatted + ':' : ''}${minutesFormatted}:${secondsFormatted}`
}

export const getMapComponent = <D, P, R = JSX.Element | null>(
  map: Map<D, (props: P) => R>,
  decider: D,
  props?: P
) => {
  const component = map.has(decider) ? map.get(decider) : null

  return component ? component(props as P) : null
}

export function stopPropagation(event: SyntheticEvent<HTMLElement> | CheckboxChangeEvent | Event) {
  event.stopPropagation()

  if (event.hasOwnProperty('nativeEvent')) {
    event.nativeEvent.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
}

export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error(error)

    return null
  }
}
