import React from 'react'

import styles from './SignUpFormInfo.module.scss'

type TItem = {
  label: string
  value: string

  hidden?: boolean
}
export type TSignUpFormInfoProps = {
  items?: TItem[]
}

export const SignUpFormInfo = ({ items = [] }: TSignUpFormInfoProps) =>
  items.length ? (
    <div className={styles.root}>
      {items
        .filter(({ hidden }) => !hidden)
        .map(({ label, value }) => (
          <div key={value} className={styles.item}>
            <span className={styles.label}>{label}</span>
            <span className={styles.value}>{value}</span>
          </div>
        ))}
    </div>
  ) : null
