import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  ClipboardEventHandler,
  ReactNode
} from 'react'
import cls from 'classnames'

import { TwoFactorCodeInput } from 'App/components'
import { isBackspaceKey, isDeleteKey, isLeftArrowKey, isRightArrowKey, isSpaceBarKey } from 'utils'

import styles from './TwoFactorCodeGroupe.module.scss'

export type TTwoFactorCodeGroupeProps = {
  onChange: (code: string, filled: boolean) => void
  value: string

  invalidText?: ReactNode
  invalid?: boolean
  whenShowSeparator?: number[]
  quantity?: number
}

const DEFAULT_QUANTITY = 6
const DEFAULT_INVALID_TEXT = 'This code isn’t valid'
const DEFAULT_WHEN_SHOW_SEPARATOR = [2]
const ONLY_NUMBER_REG_EXP = /[^0-9.]/g

const replaceToNumber = (value: string) => value.replace(ONLY_NUMBER_REG_EXP, '')
const isInputValueValid = (value: string) =>
  !isNaN(parseInt(value, 10)) && value.trim().length === 1

export const TwoFactorCodeGroupe = ({
  onChange,
  quantity = DEFAULT_QUANTITY,
  whenShowSeparator = DEFAULT_WHEN_SHOW_SEPARATOR,
  invalid = false,
  invalidText = DEFAULT_INVALID_TEXT,
  value
}: TTwoFactorCodeGroupeProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [activeInput, setActiveInput] = useState<number>(0)
  const [values, setValues] = useState<string[]>([])

  useEffect(() => {
    setValues(value.split(''))

    if (!value.length) {
      setActiveInput(0)
    }
  }, [value])

  useEffect(() => {
    onChange(values.join(''), values.filter(Boolean).length === quantity)
  }, [values, onChange, quantity])

  const changeCodeAtFocus = useCallback(
    (inputValue) => {
      const newValues = [...values]
      newValues[activeInput] = inputValue[0]

      setValues(newValues)
    },
    [activeInput, values]
  )

  const focusInput = useCallback(
    (active: number) => {
      setActiveInput(Math.max(Math.min(quantity - 1, active), 0))
    },
    [quantity]
  )

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      if (isInputValueValid(target.value)) {
        changeCodeAtFocus(target.value)
      }
    },
    [changeCodeAtFocus]
  )

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { keyCode, key } = event

      switch (true) {
        case isBackspaceKey(keyCode, key): {
          event.preventDefault()
          changeCodeAtFocus('')
          focusPrevInput()
          break
        }

        case isDeleteKey(keyCode, key): {
          event.preventDefault()
          changeCodeAtFocus('')
          break
        }
        case isLeftArrowKey(keyCode, key): {
          event.preventDefault()
          focusPrevInput()
          break
        }
        case isRightArrowKey(keyCode, key): {
          event.preventDefault()
          focusNextInput()
          break
        }
        case isSpaceBarKey(keyCode, key): {
          event.preventDefault()
          break
        }
        default:
          break
      }
    },
    [changeCodeAtFocus, focusNextInput, focusPrevInput]
  )

  const handleOnInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      if (isInputValueValid(target.value)) {
        focusNextInput()
      }
    },
    [focusNextInput]
  )

  const handleOnPaste: ClipboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault()

      const { clipboardData } = event
      const newValues = [...values]
      const parsedPastedData = replaceToNumber(clipboardData.getData('text/plain'))
      let nextActiveInput = activeInput

      if (parsedPastedData.length === quantity) {
        setActiveInput(quantity - 1)
        focusInput(quantity - 1)
        setValues(parsedPastedData.split(''))
        return
      }

      const pastedData = parsedPastedData.slice(0, quantity - activeInput).split('')

      // Paste data from focused input onwards
      for (let index = 0; index < quantity; index += 1) {
        if (index >= activeInput && pastedData.length > 0) {
          newValues[index] = pastedData.shift() ?? ''
          nextActiveInput += 1
        }
      }

      setActiveInput(nextActiveInput)
      focusInput(nextActiveInput)
      setValues(newValues)
    },
    [activeInput, focusInput, quantity, values]
  )

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    () => setActiveInput(-1),
    []
  )

  const handleOnFocus = useCallback(
    (index: number): FocusEventHandler<HTMLInputElement> =>
      ({ target }) => {
        setActiveInput(index)
        target.select()
      },
    []
  )

  return (
    <>
      <div className={cls(styles.root, invalid && styles.invalid)} ref={rootRef}>
        {Array.from({ length: quantity }).map((_, index) => (
          <TwoFactorCodeInput
            invalid={invalid}
            index={index}
            key={index}
            value={values[index] ?? ''}
            focus={activeInput === index}
            shouldAutoFocus={index === 0}
            shouldShowSeparator={whenShowSeparator.includes(index)}
            last={index === quantity - 1}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus(index)}
            onPaste={handleOnPaste}
            onKeyDown={handleOnKeyDown}
            onChange={handleOnChange}
            onInput={handleOnInput}
          />
        ))}
      </div>

      {invalid && <p className={styles.invalidText}>{invalidText}</p>}
    </>
  )
}
