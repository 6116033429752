import React, { createRef, useCallback, useState } from 'react'

import config from 'config'

import { GoogleReCaptcha, TCaptcha, TCaptchaToken, TOnChange } from '../GoogleReCaptcha'

export const useGoogleReCaptcha = () => {
  const recaptchaRef = createRef<TCaptcha>()
  const [isProcessing, setProcessing] = useState(false)

  const disabledCaptcha = config.recaptchaDisabled
  const siteKey = config.reCaptchaKey

  const resetCaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset()
    }
  }, [recaptchaRef])

  const executeCaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      setProcessing(true)
      recaptchaRef.current.execute()
    }
  }, [recaptchaRef])

  const executeCaptchaAsync = useCallback(async (): Promise<TCaptchaToken> => {
    if (recaptchaRef.current) {
      try {
        setProcessing(true)
        return await recaptchaRef.current.executeAsync()
      } catch (error) {
        throw error
      } finally {
        setProcessing(false)
      }
    }

    return null
  }, [recaptchaRef])

  const renderCaptcha = useCallback(
    (onChange?: TOnChange) =>
      config.recaptchaDisabled ? null : (
        <GoogleReCaptcha
          disable={disabledCaptcha}
          siteKey={siteKey}
          customRef={recaptchaRef}
          onChange={(token) => {
            setProcessing(false)
            onChange && onChange(token)
          }}
          onErrored={resetCaptcha}
          onExpired={resetCaptcha}
        />
      ),
    [disabledCaptcha, recaptchaRef, resetCaptcha, siteKey]
  )

  return {
    recaptchaRef,
    disabledCaptcha,
    isProcessing,
    executeCaptcha,
    resetCaptcha,
    renderCaptcha,
    executeCaptchaAsync
  }
}
