import React, { memo } from 'react'

import { TTwoFactorCheckerProps, TwoFactorCountdown } from 'App/components'

import styles from './TwoFactorTimer.module.scss'

export type TTwoFactorTimerProps = Pick<
  TTwoFactorCheckerProps,
  'codeExhausted' | 'expiredTokenDate' | 'processing'
> & {
  timeOver: boolean
  setTimeOver: (value: boolean) => void
}

const TwoFactorTimerView = ({
  codeExhausted,
  expiredTokenDate,
  processing,
  timeOver,
  setTimeOver
}: TTwoFactorTimerProps) => {
  if (processing || !expiredTokenDate) {
    return null
  }

  if (codeExhausted) {
    return <p className={styles.error}>Too many attempts. Your code is expired</p>
  }

  if (timeOver) {
    return <p className={styles.error}>The current code is expired</p>
  }

  return (
    <p className={styles.time}>
      The current code is valid for{' '}
      {<TwoFactorCountdown end={new Date(expiredTokenDate)} onTimerStop={setTimeOver} />}
    </p>
  )
}

export const TwoFactorTimer = memo(TwoFactorTimerView)
