import moment from 'moment'
import { useEffect, useState } from 'react'

import { DEFAULT_TIME_HELPER, SECOND } from 'globalConstants'

export const useCountdown = (end: Date) => {
  const [time, setTime] = useState(Date.now() + DEFAULT_TIME_HELPER)
  const [timerId, setTimerId] = useState<NodeJS.Timeout>()
  const timestamp = Math.abs(moment(time).diff(end, 'seconds'))
  const [timeOver, setTimeOver] = useState(false)

  useEffect(() => {
    if (time >= +moment(end)) {
      if (timerId) {
        clearInterval(timerId)
      }

      setTimeOver(true)
    }
  }, [end, time, timerId])

  useEffect(() => {
    if (!timeOver && timestamp === 0) {
      setTimeOver(true)
    }
  }, [setTimeOver, timeOver, timestamp])

  useEffect(() => {
    const intervalId = setInterval(() => setTime(+moment()), SECOND)
    setTimerId(intervalId)
    return () => clearInterval(intervalId)
  }, [end])

  useEffect(() => {
    if (timerId && timeOver) {
      clearInterval(timerId)
    }
  }, [timerId, timeOver])

  return { timeOver, timestamp }
}
