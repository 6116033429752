import React from 'react'
import { Field } from 'react-final-form'

import validation from 'utils/validation'
import { TextField } from 'App/components/common'
import { MAX_LENGTH_254 } from 'globalConstants'

import styles from './SignUpByLinkEmailField.module.scss'

type TSignUpByLinkEmailFieldProps = {
  onChange: (value: string) => void
}

export const SignUpByLinkEmailField = ({ onChange }: TSignUpByLinkEmailFieldProps) => (
  <Field
    name="email"
    validate={validation.composeValidators(
      validation.required(),
      validation.isEmail(),
      validation.maxLength(MAX_LENGTH_254)
    )}
  >
    {({ input, meta: { error, touched, invalid } }) => (
      <TextField
        {...input}
        onChange={(event) => {
          const value = event.target.value

          input.onChange(value.trim())
          onChange(value)
        }}
        topLabel="Email*"
        autoFocus={true}
        invalid={touched && invalid}
        error={error}
        classes={{ wrapper: styles.wrapper }}
      />
    )}
  </Field>
)
