import React from 'react'

import { InfoIcon } from '../InfoIcon'
import { TextField, TTextFieldProps } from '../TextField'

import styles from './TextFieldWithTooltip.module.scss'

export type TTextFieldWithTooltipProps = {
  tooltip: string
  label: string
} & TTextFieldProps

export const TextFieldWithTooltip = ({ tooltip, label, ...rest }: TTextFieldWithTooltipProps) => (
  <TextField
    {...rest}
    topLabel={
      <div className={styles.topLabel}>
        <span>{label}</span>
        <InfoIcon>{tooltip}</InfoIcon>
      </div>
    }
  />
)
