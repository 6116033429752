export enum ECodeErrors {
  CASE_MEMBER_RECORD_NOT_FOUND = 'CASE_MEMBER_RECORD_NOT_FOUND',
  FILE_EXIST = 'FILE_EXIST',
  FILE_INVALID_NAME = 'FILE_INVALID_NAME',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  CASE_CANNOT_BE_MODIFIED = 'CASE_CANNOT_BE_MODIFIED',
  CASE_INVITE_LOCKED = 'CASE_INVITE_LOCKED',
  CASE_INVITE_NOT_FOUND = 'CASE_INVITE_NOT_FOUND',
  CASE_LOCKED = 'CASE_LOCKED',
  CASE_FILE_PERMISSION_NOT_FOUND = 'CASE_FILE_PERMISSION_NOT_FOUND',
  ASSOCIATE_ALREADY_INVITED = 'ASSOCIATE_ALREADY_INVITED',
  ASSOCIATE_BETWEEN_USERS_ALREADY_EXISTS = 'ASSOCIATE_BETWEEN_USERS_ALREADY_EXISTS',
  USER_INVITE_TO_PLATFORM_BUSINESS_NOT_ALLOWED = 'USER_INVITE_TO_PLATFORM_BUSINESS_NOT_ALLOWED',
  SELF_INVITE_DISALLOWED = 'SELF_INVITE_DISALLOWED',
  NO_ASSOCIATE_EXIST = 'NO_ASSOCIATE_EXIST',
  TOKEN_INVALID = 'TOKEN_INVALID',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  USER_INVITE_BAD_STATUS = 'USER_INVITE_BAD_STATUS',
  INVITATION_NOT_EXIST = 'INVITATION_NOT_EXIST',
  USER_INVITE_EXPIRED = 'USER_INVITE_EXPIRED',
  ACCOUNT_EXISTS = 'ACCOUNT_EXISTS',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  LOG_INTO_YOUR_ACCOUNT = 'LOG_INTO_YOUR_ACCOUNT'
}
