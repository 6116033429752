import * as actionTypes from './ModalRootContext.actionTypes'
import { TModalState } from './ModalRootContext.types'

const MODAL_WIDTH = 530

export const initialModalState: TModalState = {
  modalType: null,
  modalProps: null,
  modalTitle: null,
  modalWidth: MODAL_WIDTH,
  closable: true
}

export const modalReducer = (
  state: TModalState = initialModalState,
  action: { type: keyof typeof actionTypes; payload?: any }
): TModalState => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL: {
      const {
        modalTitle,
        modalIcon,
        modalType,
        getContainer,
        className,
        modalProps = null,
        modalWidth = MODAL_WIDTH,
        closable = true
      } = action.payload

      return {
        ...initialModalState,
        modalTitle,
        modalType,
        closable,
        getContainer,
        modalProps,
        modalWidth,
        className,
        modalIcon
      }
    }

    case actionTypes.HIDE_MODAL: {
      return {
        ...state,
        ...initialModalState
      }
    }

    case actionTypes.SET_MODAL_TITLE: {
      const { modalTitle } = action.payload

      return {
        ...state,
        modalTitle
      }
    }

    default:
      return state
  }
}
