import React, { FC, ReactNode } from 'react'

import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { ReactComponent as SpinnerIcon } from 'assets/images/spinner.svg'

import { ButtonPrevNext } from '../common'
import { useAdaptiveLayout } from '../hooks'
import { BrandCardHeader } from '../BrandCardHeader'

import styles from './BrandCard.module.scss'

export type TBrandCard = {
  loading?: boolean
  footer?: ReactNode
  title?: ReactNode
  onBack?: () => void
}

export const BrandCard: FC<TBrandCard> = ({ loading = false, children, footer, title, onBack }) => {
  const { isMobile } = useAdaptiveLayout()

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.holder}>
          <div className={styles.sidebar}>
            <h1>Welcome to</h1>
            <Logo className={styles.logo} />
            <div className={styles.bgWrapper}>
              <div className={styles.bg} />
            </div>
          </div>
          <div className={styles.content}>
            {loading ? (
              <div className={styles.loading}>
                <SpinnerIcon />
              </div>
            ) : (
              <>
                {(title || onBack) && <BrandCardHeader title={title} onBack={onBack} />}
                {children}
              </>
            )}
            {(footer || onBack) && (
              <footer className={styles.footer}>
                {
                  <>
                    {footer}
                    {onBack && isMobile && <ButtonPrevNext type="prev" onClick={onBack} />}
                  </>
                }
              </footer>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
