import { useLayoutEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PlatformInvitationType } from '@medentee/enums'

import config from 'config'
import { redirectKey } from 'globalConstants'
import { useSessionExist } from 'App/components/hooks'
import { Spinner } from 'App/components'
import { getMapComponent } from 'utils'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

export type TJoinParams = {
  id?: string
  type?: string
  token?: string
}

type TJoinProps = {
  platformInvitationType: PlatformInvitationType
}

const URI_MAP = new Map<PlatformInvitationType, () => string>()
  .set(PlatformInvitationType.COMMUNITY, () => 'join/communities')
  .set(PlatformInvitationType.EVENT, () => 'join/events')
  .set(PlatformInvitationType.STAFF, () => 'join/organizations')

export const Join = ({ platformInvitationType }: TJoinProps) => {
  const { id, type, token } = useParams<TJoinParams>()
  const { replace } = useHistory()

  const { sessionExist, sessionResponse } = useSessionExist()
  useLayoutEffect(() => {
    if (sessionResponse.isFetched) {
      const hasRedirectPayload = id && type && token
      const redirectPath = getMapComponent(URI_MAP, platformInvitationType)
      const redirectUrl = `${config.defaultRedirectUrl}/${redirectPath}/${id}/${type}/t/${token}`

      if (sessionExist && hasRedirectPayload) {
        window.sessionStorage.removeItem(redirectKey)
        window.location.href = redirectUrl
      }

      if (!sessionExist && hasRedirectPayload) {
        window.sessionStorage.setItem(redirectKey, redirectUrl)
        replace(LOGIN_VIA_CODE_URL)
      }
    }
  }, [sessionExist, sessionResponse.isFetched, replace, token, id, type, platformInvitationType])

  return <Spinner />
}
