import React, { FC } from 'react'

import { ButtonSpinner, TButtonSpinnerProps } from 'App/components'

import styles from './Spinner.module.scss'

export type TSpinnerProps = TButtonSpinnerProps

export const Spinner: FC<TSpinnerProps> = (props) => (
  <div className={styles.root}>
    <ButtonSpinner width={30} height={30} color="secondary" {...props} />
  </div>
)
