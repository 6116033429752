import { ReactNode } from 'react'

import { Tooltip } from '../Tooltip'
import { ReactComponent as HelpIcon } from '../../../../assets/icons/Help.svg'

import styles from './InfoIcon.module.scss'

export type TInfoIconProps = {
  children: ReactNode
}

export const InfoIcon = ({ children }: TInfoIconProps) => (
  <Tooltip title={children}>
    <HelpIcon className={styles.icon} />
  </Tooltip>
)
