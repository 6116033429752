import { UAParser } from 'ua-parser-js'

const ua = new UAParser()

export const getDeviceInfo = () => {
  const device = ua.getDevice()
  const os = ua.getOS()

  const isMobile = device.type === 'mobile'

  return {
    os,
    device,
    isMobile,
    isAndroid: os.name === 'Android',
    isIOS: os.name === 'iOS'
  }
}
