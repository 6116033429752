import React, { useCallback, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useMutation } from '@tanstack/react-query'
import { PlatformInvitationType } from '@medentee/enums'
import queryString from 'query-string'

import { toastDefaultOptions } from 'globalConstants'
import { handleError, isMatchErrorCode } from 'utils'
import { getRegistrationJWT } from 'api/signup'
import { TError } from 'types'
import { ECodeErrors } from 'enums'
import {
  BrandCard,
  BrandCardHeader,
  Spinner,
  Button,
  toast,
  useGoogleReCaptcha
} from 'App/components'
import { TCaptchaToken } from 'App/components/GoogleReCaptcha'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { LOGIN_VIA_CODE_URL, LOGIN_VIA_PASSWORD_URL, SIGNUP_USER_URL } from 'utils/urls'
import { authorizeViaCode } from 'api/auth'

import { SignUpByLinkEmailField } from './SignUpByLinkEmailField'
import { useSignUpByLinkRedirect } from './useSignUpByLinkRedirect'
import styles from './SignUpByLink.module.scss'

type TParams = {
  token: string
}

type TValues = {
  email: string
}

type TSignUpByLinkProps = {
  platformInvitationType?: PlatformInvitationType
}

export const SignUpByLink = ({ platformInvitationType }: TSignUpByLinkProps) => {
  const history = useHistory()

  const { token } = useParams<TParams>()

  const { shouldShowLoading } = useSignUpByLinkRedirect({ token, platformInvitationType })

  const { disabledCaptcha, isProcessing, resetCaptcha, executeCaptchaAsync, renderCaptcha } =
    useGoogleReCaptcha()

  const [alreadyRegistered, setAlreadyRegistered] = useState(false)
  const [email, setEmail] = useState<string>()

  const { isLoading: isConfirming, mutate } = useMutation(
    ['get-registration-jwt'],
    getRegistrationJWT,
    {
      onSuccess: (data) => {
        const url = generatePath(SIGNUP_USER_URL, { token: data.jwt })

        history.push(url, { goBack: true })
      },
      onError: (e: TError) => {
        if (isMatchErrorCode(e, ECodeErrors.INVITATION_NOT_EXIST)) {
          history.push(LOGIN_VIA_CODE_URL)
          toast.error(
            'Sorry, your invite link expired. Please ask your partner to create another one.',
            toastDefaultOptions
          )
          return
        }

        if (isMatchErrorCode(e, ECodeErrors.ACCOUNT_EXISTS)) {
          setAlreadyRegistered(true)
          return
        }

        handleError(e)
      }
    }
  )

  const { isLoading: isSendingCode, mutate: sendCode } = useMutation(
    ['send-code'],
    authorizeViaCode,
    {
      onSuccess: (_, { values }) => {
        const searchQuery = queryString.stringify({ email: values.email })
        const url = `${LOGIN_VIA_CODE_URL}?${searchQuery}`

        history.push(url, { goBack: true })
      }
    }
  )

  const handleFormSubmit = useCallback(
    async (formValues: Partial<TValues>) => {
      if (!formValues.email) {
        return
      }

      let recaptchaResponse: TCaptchaToken = null

      if (!disabledCaptcha) {
        resetCaptcha()
        recaptchaResponse = await executeCaptchaAsync()
      }

      mutate({ email: formValues.email, token, recaptchaResponse })
    },
    [disabledCaptcha, executeCaptchaAsync, mutate, resetCaptcha, token]
  )

  const handleSendEmailCodeClick = useCallback(() => {
    sendCode({ values: { email } })
  }, [email, sendCode])

  const handleUsePasswordClick = useCallback(() => {
    history.push(LOGIN_VIA_PASSWORD_URL)
  }, [history])

  const handleEmailChange = useCallback((value: string) => {
    setEmail(value)
    setAlreadyRegistered(false)
  }, [])

  if (shouldShowLoading) {
    return <Spinner />
  }

  return (
    <>
      {renderCaptcha()}

      <BrandCard>
        <BrandCardHeader title="Registration" showLoginLink={false} />
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, form }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <SignUpByLinkEmailField onChange={handleEmailChange} />

              {alreadyRegistered && (
                <div className={styles.info}>
                  This email is already registered. Please log in using one of the methods below.
                </div>
              )}

              <div className={styles.buttons}>
                {!alreadyRegistered ? (
                  <Button
                    type="submit"
                    loading={isConfirming || isProcessing}
                    disabled={form.getState().invalid}
                  >
                    Confirm
                  </Button>
                ) : (
                  <>
                    <Button
                      loading={isSendingCode || isProcessing}
                      disabled={form.getState().invalid}
                      onClick={handleSendEmailCodeClick}
                    >
                      Send Email Code
                    </Button>
                    <Button
                      variant="inline"
                      fontWeight="bold"
                      icon={<LockIcon />}
                      onClick={handleUsePasswordClick}
                    >
                      Use Password
                    </Button>
                  </>
                )}
              </div>
            </form>
          )}
        </Form>
      </BrandCard>
    </>
  )
}
