import React, { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import cls from 'classnames'

import { ReactComponent as DoubleArrowIcon } from 'assets/icons/DoubleChevronLeft.svg'

import styles from './ButtonPrevNext.module.scss'

enum EType {
  NEXT = 'next',
  PREV = 'prev'
}

export type TButtonPrevNextProps = {
  type: 'next' | 'prev'
  to?: string
  onClick?: () => void
  buttonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  linkProps?: Omit<LinkProps, 'to'>
}

export const ButtonPrevNext: FC<TButtonPrevNextProps> = ({
  buttonProps,
  linkProps,
  to,
  onClick,
  type,
  children
}) => {
  const isNext = type === EType.NEXT
  const child = children ?? (isNext ? 'Next' : 'Back')
  const content = (
    <div className={styles.content}>
      {isNext ? (
        <>
          {child} <DoubleArrowIcon className={styles.next} />
        </>
      ) : (
        <>
          <DoubleArrowIcon className={styles.prev} /> {child}
        </>
      )}
    </div>
  )

  if (to !== undefined) {
    return (
      <Link className={styles.btn} to={to} {...linkProps}>
        {content}
      </Link>
    )
  }

  return (
    <button
      type="button"
      {...buttonProps}
      className={cls({
        [styles.btn]: true,
        [buttonProps?.className ?? '']: !!buttonProps?.className
      })}
      onClick={onClick}
    >
      {content}
    </button>
  )
}
