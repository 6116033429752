import React, { memo } from 'react'
import cls from 'classnames'

import { Tooltip } from 'App/components/common'
import { EIconSize } from 'globalConstants'

import styles from './IconButton.module.scss'

type TIconButton = {
  iconComponent: JSX.Element
  iconSize: EIconSize
  toolTip?: string
  onClick?: (event?: React.MouseEvent) => void
  disabled?: boolean
  classes?: Record<'root', string>
}

const IconButtonView: React.FC<TIconButton> = ({
  iconComponent,
  toolTip,
  disabled,
  iconSize,
  classes,
  onClick
}) =>
  !!toolTip && !disabled ? (
    <Tooltip title={toolTip}>
      <button className={cls(styles.root, styles[iconSize], classes?.root)} onClick={onClick}>
        {iconComponent}
      </button>
    </Tooltip>
  ) : (
    <button
      type="button"
      className={cls(styles.root, styles[iconSize], classes?.root)}
      disabled={disabled}
      onClick={onClick}
    >
      {iconComponent}
    </button>
  )

export const IconButton: React.FC<TIconButton> = memo(IconButtonView)
