const config = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  defaultRedirectUrl: process.env.REACT_APP_DEFAULT_REDIRECT_URL || '',
  apiAuthUrl: process.env.REACT_APP_API_AUTH_URL || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  // 1=disabled; 0=enabled
  recaptchaDisabled: process.env.REACT_APP_RECAPTCHA_DISABLED === '1',
  deepLinkPrefix: process.env.REACT_APP_DEEP_LINK_PREFIX || 'medentee'
}

export default config
