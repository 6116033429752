import { ComponentProps, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Modal } from '../common/Modal'
import { DownloadMobileAppDialog } from '../DownloadMobileApp/DownloadMobileAppDialog'

import { EModalComponents } from './ModalRoot.enums'
import { useModalRootContext } from './ModalRootContext'

const MODAL_COMPONENTS_MAP = {
  [EModalComponents.DOWNLOAD_MOBILE_APP_DIALOG]: DownloadMobileAppDialog

  /*
    Use relative imports for components
    Example:
    import { ScheduleMeetingModal } from 'App/components/ScheduleMeetingModal'
  */
} as const

export type TModalProps<T extends EModalComponents> = ComponentProps<
  typeof MODAL_COMPONENTS_MAP[T]
> & {
  onClose?: () => void
}

export type TModalPropsMap = {
  [Key in EModalComponents]: TModalProps<Key>
}

export type TModalPropsUnion = TModalPropsMap[keyof typeof EModalComponents]

const getModalComponent = <T extends EModalComponents>(
  modalType: T,
  modalProps: TModalProps<T> | null
) => {
  const Component = MODAL_COMPONENTS_MAP[modalType]

  return <Component {...(typeof modalProps === 'object' ? modalProps : ([] as any))} />
}

export const ModalRoot = () => {
  const {
    modalTitle,
    modalType,
    hideModal,
    modalProps,
    modalWidth,
    getContainer,
    className,
    modalIcon,
    closable = true
  } = useModalRootContext()

  const { pathname } = useLocation()

  useEffect(() => {
    hideModal()
  }, [pathname, hideModal])

  const handleModalClose = useCallback(() => {
    hideModal()

    if (modalProps?.onClose) {
      modalProps.onClose()
    }
  }, [hideModal, modalProps])

  if (!modalType) {
    return null
  }

  const ModalChildren = getModalComponent(modalType, modalProps)

  return (
    <Modal
      visible={!!modalType}
      onCancel={handleModalClose}
      title={modalTitle}
      footer={null}
      width={modalWidth}
      getContainer={getContainer}
      closable={closable}
      keyboard={closable}
      maskClosable={closable}
      className={className}
      icon={modalIcon}
    >
      {ModalChildren}
    </Modal>
  )
}
