// import { FC, useEffect, useState } from 'react'

// import { Spinner } from 'App/components/Spinner'

// import { useDownloadMobileAppDialog } from '../useDownloadMobileAppDialog'
import { FC } from 'react'

export const DownloadMobileAppInterrupt: FC = ({ children }) => (
  // const [shouldRenderChildren, setShouldRenderChildren] = useState(false)

  // const { showModal, shouldShowModal } = useDownloadMobileAppDialog()
  // useEffect(() => {
  //   setShouldRenderChildren(!shouldShowModal)

  //   if (shouldShowModal) {
  //     const callback = () => setShouldRenderChildren(true)

  //     showModal({
  //       onDownload: callback,
  //       onContinue: callback,
  //       onClose: callback
  //     })
  //   }
  // }, [shouldShowModal, showModal])

  // return shouldRenderChildren ? <>{children}</> : <Spinner />
  <>{children}</>
)
