import {
  AccountTypeNames,
  BusinessAccountRoleIdEnum,
  BusinessAccountRoleNameEnum,
  SessionPlatformEnum
} from '@medentee/enums'

import { TCaptchaToken } from 'App/components/GoogleReCaptcha'
import { API, api } from 'services/api'

type TSessionDTO = {
  userId: string
  typeId: string
  sessionId: string
  privateAccountId: string
  platform: SessionPlatformEnum
  deviceId: null
  fcmToken: null
  oneSignalToken: null
}

export const checkSession = async () => {
  const { data } = await api.get<TSessionDTO>(`${API.API_AUTH_URL}${API.CHECK_SESSION}`)

  return data
}

export type TLoginValues = {
  email?: string
  token?: string
}

export type TAccountRole = {
  id: BusinessAccountRoleIdEnum
  name: BusinessAccountRoleNameEnum
  createdAt?: string | Date
  updatedAt?: string | Date
}

export type TSpecialization = {
  id: string
  name: string
}

export type TAssociatesProfessions = {
  id: string
  name: string
  specializations: TSpecialization[]
}

export type TAccountCity = {
  id: string
  cityName: string
  countryCode: string
  timezone: string
}

export type TAccountCountry = {
  code: string
  countryName: string
}

export type TAccount = {
  lastSeen: string
  id: string
  email: string
  suspended: boolean
  firstName: string
  lastName: string
  type: {
    id: string
    name: AccountTypeNames
  }
  companyName: string | null
  displayUserName: string
}

export type TAccountData = {
  id: string
  email: string
  suspended: boolean
  firstName: string
  lastName: string
  professions: TAssociatesProfessions[]
  type: {
    id: string
    name: AccountTypeNames
  }
  city: TAccountCity | null
  country: TAccountCountry
  lastSeen: string
  companyName: string
  hasBusinessAccount: boolean
  displayUserName: string
  professionalAccount?: TAccount
  unreadNotificationCount?: number
  role?: TAccountRole
  hasConnectedAccount?: boolean
}

export type TAuthorizationResponse =
  | {
      error?: any
    }
  | TAccountData

export type TAuthorizationOptions = {
  values: TLoginValues
  recaptchaResponse?: TCaptchaToken
}

export const authorizeViaCode = async ({ values, recaptchaResponse }: TAuthorizationOptions) =>
  await api.post<TAuthorizationResponse>(`${API.API_AUTH_URL}${API.LOGIN_VIA_CODE}`, {
    ...values,
    recaptchaResponse
  })
