import { useQuery } from '@tanstack/react-query'
import noop from 'lodash/noop'

import { checkSession } from 'api/auth'

export const useSessionExist = () => {
  const sessionResponse = useQuery(['check-session'], checkSession, {
    cacheTime: 0,
    onError: noop
  })

  const sessionExist = Boolean(sessionResponse.data?.sessionId)

  const shouldShowLoading = sessionExist || sessionResponse.isFetching

  return {
    sessionResponse,
    sessionExist,
    shouldShowLoading
  }
}
