import React, { FC, RefObject, useEffect } from 'react'
import ReCaptcha from 'react-google-recaptcha'

export type TCaptcha = ReCaptcha
export type TCaptchaToken = string | null
export type TOnChange = (token: TCaptchaToken) => void
export type TCaptchaProps = {
  siteKey: string
  customRef: RefObject<ReCaptcha>
  onErrored?: () => void
  onExpired?: () => void
  onChange?: TOnChange
  disable: boolean
}

type TDisableComponent = {
  onChange?: TOnChange
  customRef: RefObject<ReCaptcha>
}
const DisableComponent = ({ onChange, customRef }: TDisableComponent) => {
  useEffect(() => {
    if (customRef) {
      ;(customRef as any).current = {
        execute: () => {
          onChange && onChange('')
        },
        reset: () => {
          // empty
        }
      }
    }
  }, [customRef, onChange])

  return (
    <div
      style={{
        width: 1,
        height: 1,
        position: 'fixed',
        top: '-1px',
        left: '-1px',
        visibility: 'hidden',
        opacity: 0,
        pointerEvents: 'none'
      }}
    />
  )
}

export const GoogleReCaptcha: FC<TCaptchaProps> = ({ customRef, siteKey, disable, ...props }) => {
  if (disable) {
    return <DisableComponent customRef={customRef} onChange={props.onChange} />
  }

  return <ReCaptcha ref={customRef} size="invisible" {...props} sitekey={siteKey} />
}

export { ReCaptcha }
