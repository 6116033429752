import React, { ReactNode } from 'react'

import styles from './BrandCardActions.module.scss'

export type TBrandCardActionsProps = {
  start?: ReactNode | null
  center?: ReactNode | null
  end?: ReactNode | null
}

export const BrandCardActions = ({
  start = null,
  center = null,
  end = null
}: TBrandCardActionsProps) => (
  <div className={styles.root}>
    <div>{start}</div>
    <div>{center}</div>
    <div>{end}</div>
  </div>
)
